import { useRouter } from 'next/router'
import { Dispatch, SetStateAction, useState } from 'react'
import {
  Row,
  Column,
  Text,
  Divider,
  Button,
  ToggleSwitch
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import {
  hashRut,
  postGiftcardGenerateOrder
} from '@smu-chile/pkg-unimarc-hooks'
import { getValue } from 'shared/helpers'
import { IPaymentData } from 'shared/interfaces/IFormValidations'
import styles from '../cart.module.css'
import { IGiftcardValues } from 'shared/interfaces/IGiftcardValues'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

type OrderSummaryProps = {
  dataDropdown?: [IGiftcardValues]
  isMobile?: boolean
  isValid: boolean
  paymentData: IPaymentData
  setShowModal: Dispatch<SetStateAction<boolean>>
  setValue: Dispatch<SetStateAction<string>>
  width?: string
}

export const OrderSummary = ({
  dataDropdown,
  isMobile,
  isValid = false,
  setShowModal,
  setValue,
  paymentData,
  width = '450px'
}: OrderSummaryProps) => {
  const router = useRouter()
  const [isToggle, setIsToggle] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { executeRecaptcha } = useGoogleReCaptcha()
  const giftcardData = JSON.parse(localStorage.getItem('giftcardForm') ?? '{}')
  const campaign = localStorage.getItem('gcCampaign') ?? ''
  const total = new Intl.NumberFormat('es-CL').format(
    getValue(giftcardData?.giftCardAmount) *
      getValue(giftcardData?.giftCardQuantity)
  )
  const dropdownValues = Object.entries(dataDropdown).map((entry) => {
    return {
      value: entry?.[1]?.['label'],
      id: entry?.[1]?.['idRequest']
    }
  })

  const handlePayment = async () => {
    if (isValid && isToggle) {
      const token = await executeRecaptcha('identity_lookup')
      if (!token) {
        handlePayment()
      }
      const valueId = dropdownValues.filter((giftcard) => {
        return giftcard.value === giftcardData?.giftCardAmount
      })?.[0]?.id
      setIsLoading(true)
      const reqBody = {
        buyer: {
          name: paymentData?.name,
          email: paymentData?.email
        },
        campaignName: campaign,
        giftCardQuantity: Number(giftcardData?.giftCardQuantity),
        giftCardAmount: valueId,
        beneficiaries: [...giftcardData.beneficiaries],
        emailComment: '',
        rutHash: hashRut(paymentData?.rut)?.slice(0, 15),
        phone: `+569${paymentData?.phone}`
      }
      localStorage.setItem('paymentForm', JSON.stringify(paymentData))
      const res = await postGiftcardGenerateOrder({
        requestOptions: { headers: { 'g-recaptcha-response': token } },
        data: reqBody
      })
      if (res?.status === 201) {
        router.push(`${res.url}?token_ws=${res.token}`)
      } else setIsLoading(false)
    }
  }

  return (
    <Column
      border={`solid 0.3px ${getGlobalStyle('--color-neutral-gray-divider')}`}
      borderRadius='12px'
      margin={isMobile && '2rem 0 0 0'}
      padding='16px'
      width={width}
    >
      <Row
        alignItems='center'
        margin='0 0 32px 0'
      >
        <Column width={isMobile ? '40%' : '50%'}>
          <Text
            fontSize='xl'
            fontWeight='semibold'
          >
            Resumen
          </Text>
        </Column>
        <Column>
          <Text
            color='gray-light'
            fontSize='md'
            fontWeight='regular'
          >
            {`(${giftcardData?.giftCardQuantity} Giftcard
            ${parseInt(giftcardData?.quantity) > 1 ? 's' : ''})`}
          </Text>
        </Column>
      </Row>
      <Row>
        <Column>
          <Text
            fontSize='md'
            fontWeight='semibold'
          >
            Total
          </Text>
        </Column>
        <Column alignItems='end'>
          <Text
            fontSize='md'
            fontWeight='semibold'
          >
            $ {total}
          </Text>
        </Column>
      </Row>
      <Row>
        <Divider
          color={getGlobalStyle('--color-neutral-gray-divider')}
          thickness={1}
          verticalMargin={20}
          width={95}
        />
      </Row>
      <Row margin='10px 0 32px 0'>
        <ToggleSwitch
          customClass={`${styles.switch__terms} ${
            isToggle && styles['switch__terms-active']
          }`}
          onClick={() => {
            setIsToggle(!isToggle)
          }}
          value={!isToggle}
        />
        <p className={styles.desc__terms}>
          Acepto los{' '}
          <span
            onClick={() => {
              setShowModal(true)
              setValue('termsAndConditions')
            }}
          >
            términos y condiciones
          </span>{' '}
        </p>
      </Row>
      <Row margin='0 0 16px 0'>
        <Button
          background={
            isValid && isToggle
              ? getGlobalStyle('--color-background-razzmatazz')
              : '#C8CBD0'
          }
          borderRadius='24px'
          customClassName={isValid && isToggle && 'button__form-pink'}
          isLoading={isLoading}
          label='Pagar'
          onClick={handlePayment}
          width='100%'
        />
      </Row>
    </Column>
  )
}
