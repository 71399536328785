import { IMAGES } from 'shared/utils/contants'
import {
  Column,
  Divider,
  Icon,
  Picture,
  Row,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { Card } from 'components/Card'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { MovementsProps } from '..'
import { isValidArrayWithData } from '@smu-chile/pkg-unimarc-components/shared/helpers/isValidArrayWithData'
import { formatDate } from './helpers/formatDate'
import { formatNumber } from './helpers/formatNumber'
import { useContentful } from '@smu-chile/pkg-unimarc-hooks'
import classNames from 'classnames'
import styles from '../balance.module.css'

export interface TransactionsProps {
  isMobile?: boolean
  items?: MovementsProps[]
}

const getTransactionIcon = (value: number) => {
  if (value < 0) {
    return (
      <Icon
        customSize={35}
        name='TransactionUp'
      />
    )
  }

  if (value > 0) {
    return (
      <Icon
        customSize={35}
        name='TransactionDown'
      />
    )
  }

  return (
    <Icon
      customSize={35}
      name='TransactionInfo'
    />
  )
}

export const Transactions = ({ isMobile, items }: TransactionsProps) => {
  const grapeAsset = useContentful({
    id_contentful: 'grapes-image',
    options: {
      'sys.id': IMAGES.GRAPES
    },
    type: 'assets'
  })
  const grapesImage = grapeAsset?.data?.['items']?.[0]?.fields?.file?.url

  return (
    <Card
      fontWeight='regular'
      title='Movimientos'
      width='100%'
    >
      {isMobile ? (
        <>
          {isValidArrayWithData(items) &&
            items.map((item, index) => {
              return (
                <Column key={index}>
                  <Row alignItems='center'>
                    <Column width='15%'>
                      {getTransactionIcon(item?.movementValue)}
                    </Column>
                    <Column width='50%'>
                      <Text
                        fontSize='md'
                        fontWeight='semibold'
                      >
                        {item?.movementType}
                      </Text>
                      <Text
                        color='gray-light'
                        fontSize='sm'
                      >
                        {formatDate(item?.movementDate)}
                      </Text>
                    </Column>
                    <Column
                      alignItems='end'
                      width='35%'
                    >
                      <Text
                        customClassName={classNames({
                          [styles.errorTitle]: item?.movementValue < 0,
                          [styles.successTitle]: item?.movementValue > 0
                        })}
                        fontWeight='semibold'
                      >
                        {item?.movementValue != 0 &&
                          formatNumber(item?.movementValue)}
                      </Text>
                    </Column>
                  </Row>
                  <Divider
                    color={getGlobalStyle('--color-neutral-gray-silver')}
                    thickness={1}
                    verticalMargin={20}
                    width={100}
                  />
                </Column>
              )
            })}
        </>
      ) : (
        <>
          {isValidArrayWithData(items) &&
            items
              .sort((a, b) => {
                return (
                  new Date(b.movementDate).getTime() -
                  new Date(a.movementDate).getTime()
                )
              })
              .map((item, index) => {
                return (
                  <Row
                    border={`1px solid ${getGlobalStyle(
                      '--color-neutral-gray-light'
                    )}`}
                    borderRadius={getGlobalStyle('--border-radius-md')}
                    key={index}
                    margin='0 0 20px 0'
                    padding='24px 32px'
                  >
                    <Column width='10%'>
                      {getTransactionIcon(item?.movementValue)}
                    </Column>
                    <Column width='30%'>
                      <Text fontWeight='semibold'>{item?.movementType}</Text>
                    </Column>
                    <Column width='30%'>
                      <Text color='gray-light'>
                        {formatDate(item?.movementDate)}
                      </Text>
                    </Column>
                    <Column
                      alignItems='end'
                      width='20%'
                    >
                      <Text
                        customClassName={classNames({
                          [styles.errorTitle]: item?.movementValue < 0,
                          [styles.successTitle]: item?.movementValue > 0
                        })}
                        fontWeight='semibold'
                      >
                        {item?.movementValue != 0 &&
                          formatNumber(item?.movementValue)}
                      </Text>
                    </Column>
                  </Row>
                )
              })}
        </>
      )}
      {!items?.length && (
        <Column alignItems='center'>
          <Picture
            alt='cake'
            height='311px'
            src={grapesImage}
            width='362px'
          />
          <Row
            justifyContent='center'
            margin='0 0 16px 0'
          >
            <Text
              fontSize='2xl'
              fontWeight='semibold'
              textAlign='center'
            >
              Sin Movimientos
            </Text>
          </Row>
          <Row
            justifyContent='center'
            margin='0 0 16px 0'
            width={isMobile ? '280px' : '480px'}
          >
            <Text
              fontSize='xl'
              textAlign='center'
            >
              En estos momentos no podemos mostrar los movimientos de esta
              tarjeta
            </Text>
          </Row>
        </Column>
      )}
    </Card>
  )
}
