import { Dispatch, SetStateAction, useRef } from 'react'
import {
  Container,
  Icon,
  Row,
  Text,
  Tooltip
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { TooltipProps } from '@smu-chile/pkg-unimarc-components'
import { useAfterOf } from '@smu-chile/pkg-unimarc-hooks'
import styles from './errorToast.module.css'

export interface ErrorToastProps extends ErrorToastTooltipPropsPicked {
  isMobile: boolean
  message?: string
  onClick?: () => void
  setFetchError: Dispatch<SetStateAction<boolean>>
}

type ErrorToastTooltipPropsPicked = Pick<
  TooltipProps,
  'positionContainer' | 'top'
>

export const ErrorToast = ({
  isMobile,
  message,
  onClick,
  positionContainer,
  top,
  setFetchError
}: ErrorToastProps) => {
  const wrapperRef = useRef(null)

  const dismissError = () => {
    setFetchError(false)
  }

  useAfterOf({
    onFinish: dismissError,
    ms: 4000
  })

  return (
    <Container
      maxHeight='0px'
      zIndex='100000'
    >
      <Tooltip
        alignAllItems='center'
        background={getGlobalStyle('--color-feedback-error-bg')}
        borderRadius={getGlobalStyle('--border-radius-2sm')}
        boxShadow={getGlobalStyle('--box-shadow-3md')}
        closeColor={getGlobalStyle('--color-icons-black')}
        closeIcon={false}
        margin='8px 0 0'
        maxWidth={isMobile ? 'calc(100vw - 32px)' : '390px'}
        onClick={onClick}
        padding='12px'
        position='fixed'
        positionContainer={positionContainer}
        right={isMobile ? '16px' : '100px'}
        textComponent={
          <Container
            padding='0 12px 0 0'
            ref={wrapperRef}
          >
            <Row
              alignItems='center'
              gap='8px'
            >
              <Icon
                color={getGlobalStyle('--color-feedback-error')}
                name='InfoFill'
                sizes='md'
                transform='rotate(180deg)'
              />

              <Text
                color='black'
                fontSize='md'
              >
                {message}
              </Text>

              <Text
                customClassName={styles.retryButton}
                fontSize='sm'
                onClick={onClick}
              >
                Reintentar
              </Text>
            </Row>
          </Container>
        }
        top={top}
        type='top'
        width={isMobile ? 'calc(100vw - 32px)' : '390px'}
      />
    </Container>
  )
}
