import { useMemo } from 'react'
import { useRouter } from 'next/router'
import {
  Button,
  Column,
  Container,
  Picture,
  Row,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { Context as ResponsiveContext } from '@smu-chile/pkg-unimarc-components/reactResponsive'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import styles from './errorContent.module.css'

export interface ErrorContentProps {
  title: string
  description?: string
  asset: string
}

export const ErrorContent = ({
  title,
  description,
  asset
}: ErrorContentProps) => {
  const { push } = useRouter()
  const { innerWidth } = useMobile()

  const isMobile = innerWidth < 1280
  const withProvider: number = useMemo(() => {
    const hasInnerWith: number = isMobile && !innerWidth ? 500 : 1400
    return innerWidth ? innerWidth : hasInnerWith
  }, [innerWidth, isMobile])

  const onClick = () => {
    return push('/cart')
  }

  return (
    <ResponsiveContext.Provider value={{ width: withProvider }}>
      <BigScreen>
        <Container
          alignItems='center'
          backgroundColor={getGlobalStyle('--color-base-white')}
          isWrap
          padding='0 30rem'
        >
          <Column alignItems='center'>
            <Picture
              alt='error'
              height='311px'
              src={asset}
              width='362px'
            />
            <Row
              justifyContent='center'
              margin='0 0 16px 0'
            >
              <Text
                fontSize='2xl'
                fontWeight='semibold'
                textAlign='center'
              >
                {title}
              </Text>
            </Row>
            <Row
              justifyContent='center'
              margin='0 0 16px 0'
              width='480px'
            >
              <Text
                fontSize='xl'
                textAlign='center'
              >
                {description}
              </Text>
            </Row>
            <Row
              justifyContent='center'
              margin='0 0 32px 0'
            >
              <Text
                customColor={getGlobalStyle('--color-primary-disabled')}
                fontSize='md'
                fontWeight='medium'
                textAlign='center'
              >
                Error: CRT-01
              </Text>
            </Row>
            <Row justifyContent='center'>
              <Button
                background={getGlobalStyle('--color-background-dark-orange')}
                borderRadius='24px'
                customClassName={styles.button__color}
                fontWeight={getGlobalStyle('--font-weight-medium')}
                label='Recargar página'
                onClick={onClick}
                width='408px'
              />
            </Row>
          </Column>
        </Container>
      </BigScreen>
      <SmallScreen>
        <Container
          alignItems='center'
          backgroundColor={getGlobalStyle('--color-base-white')}
          isWrap
          padding='0 1rem'
        >
          <Column alignItems='center'>
            <Row
              justifyContent='center'
              margin='10rem 0 0 0'
            >
              <Picture
                alt='cake'
                height='211px'
                src={asset}
                width='262px'
              />
            </Row>
            <Row
              justifyContent='center'
              margin='0 0 16px 0'
            >
              <Text
                fontSize='2xl'
                fontWeight='semibold'
                textAlign='center'
              >
                {title}
              </Text>
            </Row>
            <Row
              justifyContent='center'
              margin='0 0 16px 0'
            >
              <Text
                fontSize='lg'
                textAlign='center'
              >
                {description}
              </Text>
            </Row>
            <Row justifyContent='center'>
              <Text
                customColor={getGlobalStyle('--color-primary-disabled')}
                fontSize='md'
                fontWeight='medium'
                textAlign='center'
              >
                Error: CRT-01
              </Text>
            </Row>
            <Row
              justifyContent='center'
              margin='4rem 0 4rem 0'
            >
              <Button
                background={getGlobalStyle('--color-background-dark-orange')}
                borderRadius='24px'
                fontWeight={getGlobalStyle('--font-weight-medium')}
                label='Recargar página'
                onClick={onClick}
                width='85%'
              />
            </Row>
          </Column>
        </Container>
      </SmallScreen>
    </ResponsiveContext.Provider>
  )
}
