import { Column, Row, Text } from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { TFontWeight } from '@smu-chile/pkg-unimarc-components/shared/interfaces/TFontWeight'

export interface CardProps {
  children?: React.ReactNode
  title: string
  width: string
  height?: string
  fontWeight?: TFontWeight
}

export const Card = ({
  title,
  children,
  width,
  height,
  fontWeight = 'semibold'
}: CardProps) => {
  return (
    <Column
      border={`solid 0.3px ${getGlobalStyle('--color-neutral-gray-divider')}`}
      borderRadius='12px'
      minHeight={height}
      padding='16px'
      width={width}
    >
      <Row
        justifyContent='between'
        padding='0 0 32px 0'
      >
        <Text
          fontSize='xl'
          fontWeight={fontWeight}
        >
          {title}
        </Text>
      </Row>
      {children}
    </Column>
  )
}
