import { isProd } from '@smu-chile/pkg-unimarc-hooks/shared/constants'

export const TERMS_AND_CONDITIONS = {
  0: isProd ? '4XrerNcLnrMQoFj1ng84LA' : '13Kkj6TfLJjGp8Fhxkp04U'
}

export const PRIVACY_POLICIES = {
  0: isProd ? '1gBANafsqzQgShhsufmhUJ' : '5rlmrSYu1RpkRLdRBoi8gB'
}

export const IMAGES = {
  GRAPES: isProd ? '51iouOm8z3r36I3pytjELy' : '4aadcFHJRIDLginRnFPOgA',
  CAKE: isProd ? 'pDj95bjeNHmvFBjPmj00v' : '6Hn5ixY84tAN6fBNaCwyOH',
  LEMON: isProd ? '2qvnSUuxsCuHrtZxBMEzid' : '4yhSYuNrEILUQnDSKYR5zR',
  PASTA: isProd ? '1snTvLzRH6Lanwgn2rPWBk' : '6c0FhNd9OCkdXLNUobl73J',
  BANNER_BALANCE: isProd ? '5m5SC6M712UXZVHamnzZRx' : '3R6qAehfMs9oi25DyrQoDT',
  BANNER_GIFTCARD: isProd ? 'Z69b9qmcTUO2cMT3BSV1D' : '2icxAPG730Dy5ZeXgd8GLQ',
  ORANGE: isProd ? '5diCU7Yi85jeMyxlojAqFd' : '2MdkNOM3lrytmhtjeYip8i'
}

export const GIFTCARDS = {
  COLLAGE: isProd ? '3ImHakUNnUdvFNbAmuUjN0' : '1tX0UBtQJIGzgwaLN9Ycme',
  HOME: isProd ? '5Njr3wzDDfEMz9sMz9gHdN' : '1kY9EW2FZtblFpfxj0PEyC',
  ENTRYID_VALUES: isProd ? '3CySaVY8lg4CYqA9Lc19z8' : '39YPuUfc3n2kGRVum1DCAy',
  VALUES: ['$5.000', '$10.000', '$20.000', '$30.000', '$50.000', '$100.000'],
  QUANTITY: ['2', '3', '4', '5', '6', '7', '8', '9', '10']
}
