import { Column, Text } from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'

export const InfoGiftcard = () => {
  return (
    <Column
      border={`solid 0.3px ${getGlobalStyle('--color-neutral-gray-divider')}`}
      borderRadius='12px'
      margin='32px 0'
      padding='32px 16px'
    >
      <Column>
        <Text fontWeight='semibold'>Descripción</Text>
        <Text>
          Giftcard digital al portador, que permite compras presenciales en
          todos los supermercados Unimarc, Alvi, S10 y M10, además en
          Unimarc.cl. Permite copago de forma presencial. Aplican promociones y
          descuentos vigentes con el medio de pago Giftcard.
        </Text>
      </Column>
      <Column margin='32px 0 0 0'>
        <Text fontWeight='semibold'>¿Cómo funciona?</Text>
        <Text>
          Selecciona monto, ingresa nombre y correo del beneficiario de la
          Giftcard, una vez se ha realizado el pago exitosamente, recibirás un
          correo con detalle de la compra. Automáticamente el beneficiario
          recibirá un correo con la Giftcard digital adjunta.
        </Text>
      </Column>
    </Column>
  )
}
