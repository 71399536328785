import { useState } from 'react'
import {
  Column,
  InputLabel,
  InputLabelProps,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { useFormValidator } from './useFormValidator'
import styles from '../Cart/cart.module.css'
import classNames from 'classnames'
import { validators } from 'shared/utils/validators'

type FormCartProps = {
  onChange: (values) => void
}

export const CheckBalanceForm = ({ onChange }: FormCartProps) => {
  const [isPasswordShow, setIsPasswordShow] = useState(false)
  const [formValues, setFormValues] = useState({
    giftCardNumber: '',
    code: ''
  })

  const { errors, validateForm } = useFormValidator()

  const handleChangeForm = (event) => {
    const field = event?.target?.id
    const value = event?.target?.value

    const finalValue = validators(field, value)
    if (finalValue === undefined) return

    const formState = {
      ...formValues,
      [field]: finalValue
    }

    setFormValues(formState)

    if (errors[field].dirty)
      validateForm({
        form: formState,
        errors,
        field,
        onChange
      })
  }

  const handleCleanInputForm = (event) => {
    const getName = event?.target?.parentNode?.parentNode?.children?.[0]?.id
    setFormValues((prev) => {
      return { ...prev, [getName]: '' }
    })
  }

  const customPropsInputForm = {
    inputWidth: '100%',
    onChange: handleChangeForm,
    onClickIcon: handleCleanInputForm,
    showXIcon: false
  } as InputLabelProps

  return (
    <Column gap='30px'>
      <Column>
        <InputLabel
          {...customPropsInputForm}
          customInputClassName={classNames({
            [styles.input__focus]:
              !errors?.giftCardNumber?.error &&
              !errors?.giftCardNumber?.touched,
            [styles.input__error]: errors?.giftCardNumber?.error,
            [styles.input__success]:
              !errors?.giftCardNumber?.error && errors?.giftCardNumber?.touched
          })}
          fontSize='lg'
          id='giftCardNumber'
          label='Número de Giftcard'
          value={formValues.giftCardNumber}
        />
        {errors?.giftCardNumber?.dirty && errors?.giftCardNumber?.error ? (
          <Text
            color='error'
            customClassName={styles.text__error}
            fontSize='2xs'
          >
            {errors.giftCardNumber.message}
          </Text>
        ) : null}
      </Column>
      <Column>
        <InputLabel
          {...customPropsInputForm}
          appendIcon={isPasswordShow ? 'EyeThin' : 'EyeSlashThin'}
          autoComplete='new-password'
          customInputClassName={classNames({
            [styles.input__error]: errors?.code?.error,
            [styles.input__success]:
              !errors?.code?.error && errors?.code?.touched
          })}
          fontSize='lg'
          id='code'
          label='Pin de 6 dígitos'
          onAppendIconClick={() => {
            setIsPasswordShow(!isPasswordShow)
          }}
          type={isPasswordShow ? 'text' : 'password'}
          value={formValues.code}
        />
        {errors?.code?.dirty && errors?.code?.error ? (
          <Text
            color='error'
            customClassName={styles.text__error}
            fontSize='2xs'
          >
            {errors.code.message}
          </Text>
        ) : null}
      </Column>
    </Column>
  )
}
