import Link from 'next/link'
import { KindOfGiftcard } from './KindOfGiftcard'
import { useMemo, useState } from 'react'
import { InfoGiftcard } from './InfoGiftcard'
import { FormGiftcard } from './FormGiftcard'
import { Context as ResponsiveContext } from '@smu-chile/pkg-unimarc-components/reactResponsive'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import {
  Breadcrumbs,
  Column,
  Container,
  Row
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { cleanLocalStorage } from 'shared/helpers'
import { IGiftcardValues } from 'shared/interfaces/IGiftcardValues'

interface GiftcardProps {
  dataDropdown?: [IGiftcardValues]
}

const breadcrumbData = [
  {
    label: 'Inicio',
    url: '/',
    oneClick: () => {
      return
    }
  },
  {
    label: 'Giftcard',
    url: '/giftcard'
  }
]

export const Giftcard = ({ dataDropdown }: GiftcardProps) => {
  const [isSingleGiftcard, setIsSingleGiftcard] = useState(true)
  const { innerWidth } = useMobile()

  const isMobile = innerWidth < 1280

  const withProvider: number = useMemo(() => {
    const hasInnerWith: number = isMobile && !innerWidth ? 500 : 1400
    return innerWidth ? innerWidth : hasInnerWith
  }, [innerWidth, isMobile])

  return (
    <ResponsiveContext.Provider value={{ width: withProvider }}>
      <BigScreen>
        <Container
          backgroundColor='var(--color-base-white)'
          isWrap
          margin='6rem auto 6rem auto'
          maxWidth='1120px'
        >
          <Row padding='16px 0 32px 0'>
            <Breadcrumbs
              afterClick={cleanLocalStorage}
              changeLinkColor
              colorActiveLink='razzmatazz'
              customDivider='>'
              dividerClassName='divider__color'
              firstLinkColor='brightGrey'
              linkWrapper={Link}
              links={breadcrumbData}
            />
          </Row>
          <Row
            gap='20px'
            justifyContent='between'
          >
            <KindOfGiftcard isSingleGiftcard={isSingleGiftcard} />
            <FormGiftcard
              dataDropdown={dataDropdown}
              isSingleGiftcard={isSingleGiftcard}
              setIsSingleGiftcard={setIsSingleGiftcard}
            />
          </Row>
          <InfoGiftcard />
        </Container>
      </BigScreen>
      <SmallScreen>
        <Container
          backgroundColor={getGlobalStyle('--color-base-white')}
          isWrap
          margin='6rem auto 0 auto'
          maxWidth='1120px'
          padding='1rem'
        >
          <Row
            gap='20px'
            justifyContent='between'
          >
            <Column>
              <KindOfGiftcard
                isMobile={isMobile}
                isSingleGiftcard={isSingleGiftcard}
              />
              <FormGiftcard
                dataDropdown={dataDropdown}
                isMobile={isMobile}
                isSingleGiftcard={isSingleGiftcard}
                setIsSingleGiftcard={setIsSingleGiftcard}
              />
              <InfoGiftcard />
            </Column>
          </Row>
        </Container>
      </SmallScreen>
    </ResponsiveContext.Provider>
  )
}
