import {
  Container,
  DesktopModalWrapper,
  MobileModalWrapper
} from '@smu-chile/pkg-unimarc-components'
import { Dispatch, SetStateAction } from 'react'
import { BannerHome } from 'shared/interfaces/Home'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import styles from './termsModal.module.css'

export type TermsModalProps = {
  title: string
  padding: string
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
  data: BannerHome
  isMobile?: boolean
}

export const TermsModal = ({
  title,
  padding,
  showModal,
  setShowModal,
  data,
  isMobile
}: TermsModalProps) => {
  const onClose = () => {
    setShowModal(false)
  }

  return (
    <>
      {isMobile && showModal && (
        <MobileModalWrapper
          blockId='terms'
          body={<Container padding={padding}>{data}</Container>}
          buttonBackground='var(--color-background-dark-orange)'
          buttonLabel='Entendido'
          dragProps={{
            draggButton: false
          }}
          headerPadding='30px 0'
          headerTitle={title}
          iconSize={20}
          isEnableButton
          modalConfigsProps={{
            openAnimation: true,
            fullScreenSticky: true,
            hiddenX: true,
            isAutoHeight: true,
            isOpen: showModal,
            isWindowBlocked: true,
            marginFullScreen: '1vh 0 0',
            stickyBottom: true
          }}
          onApply={onClose}
          onClose={onClose}
        />
      )}
      {!isMobile && (
        <DesktopModalWrapper
          blockId='terms'
          footerChildrenProps={{
            default: true,
            buttonDefaultProps: {
              background: 'var(--color-background-dark-orange)',
              borderRadius: getGlobalStyle('--border-radius-xl'),
              customClassName: styles.buttonHover,
              height: '45px',
              label: 'Entendido',
              onClick: onClose,
              width: '60%'
            }
          }}
          headerCloseIcon={{ sizes: '2xs', default: true }}
          headerTitle={title}
          modalConfigsProps={{
            isOpen: showModal,
            toggleOutside: onClose,
            maxWidth: '65%',
            maxHeight: '70%'
          }}
        >
          <Container padding={padding}>{data}</Container>
        </DesktopModalWrapper>
      )}
    </>
  )
}
