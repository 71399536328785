import {
  Column,
  InputLabel,
  InputLabelProps,
  Row,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useFormValidator } from './useFormValidator'
import { postQuoteEmail, useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { ErrorToast } from 'components/ErrorToast'
import { validators } from 'shared/utils/validators'
import classNames from 'classnames'
import styles from '../home.module.css'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

export interface QuotationForm {
  setIsValid: Dispatch<SetStateAction<boolean>>
  executeFunction: boolean
  setExecuteFunction: Dispatch<SetStateAction<boolean>>
  setShowModal: Dispatch<SetStateAction<boolean>>
  setShowSuccessModal: Dispatch<SetStateAction<boolean>>
  setIsLoading: Dispatch<SetStateAction<boolean>>
}

export const QuotationForm = ({
  setIsValid,
  executeFunction,
  setExecuteFunction,
  setShowModal,
  setShowSuccessModal,
  setIsLoading
}: QuotationForm) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [fetchError, setFetchError] = useState(false)
  const [formValues, setFormValues] = useState({
    document: '',
    name: '',
    emailClient: '',
    nameCompany: '',
    code: '+569',
    phone: '',
    message: '',
    totalGiftcards: '',
    valueGiftcard: ''
  })

  const { errors, validateForm } = useFormValidator()
  const { isMobile } = useMobile()

  const handleChangeForm = (event) => {
    const field = event?.target?.id
    const value = event?.target?.value

    const finalValue = validators(field, value)
    if (finalValue === undefined) return

    const formState = {
      ...formValues,
      [field]: finalValue
    }

    setFormValues(formState)

    if (errors[field].dirty)
      validateForm({
        form: formState,
        errors,
        field,
        onChange: () => {
          return null
        }
      })
  }

  const handleCleanInputForm = (event) => {
    const field = event?.target?.parentNode?.parentNode?.children?.[0]?.id

    setFormValues((prev) => {
      const formState = { ...prev, [field]: '' }

      validateForm({
        form: formState,
        errors,
        field,
        onChange: () => {
          return null
        }
      })

      return formState
    })
  }

  const onApply = async () => {
    const token = await executeRecaptcha('identity_lookup')
    setIsLoading(true)
    postQuoteEmail({
      requestOptions: { headers: { 'g-recaptcha-response': token } },
      data: formValues
    }).then((res) => {
      if (res?.status != 200) {
        setFetchError(true)
        setExecuteFunction(false)
      } else if (res?.status == 200) {
        setShowModal(false)
        setShowSuccessModal(true)
        setExecuteFunction(false)
        localStorage.setItem('clientEmail', formValues.emailClient)
      }
      setIsLoading(false)
    })
  }

  const customPropsInputForm = {
    inputWidth: '100%',
    onChange: handleChangeForm,
    onClickIcon: handleCleanInputForm
  } as InputLabelProps

  useEffect(() => {
    let countValues = 0
    const totalValues = Object.keys(formValues).length
    for (const key in formValues) {
      if (formValues[key] && !errors[key]?.error) {
        countValues++
      }
    }

    setIsValid(totalValues === countValues)
  }, [formValues])

  useEffect(() => {
    if (executeFunction) onApply()
  }, [executeFunction])

  return (
    <>
      <Row margin='0 0 20px 0'>
        <Text>
          Si tienes más de 10 colaboradores, esta es la mejor opción para ti.
          Déjanos tus datos y pronto te contactaremos.
        </Text>
      </Row>
      <Row>
        <Column gap='30px'>
          <Column>
            <InputLabel
              {...customPropsInputForm}
              customInputClassName={classNames(styles.input__height, {
                [styles.input__error]: errors.document.error,
                [styles.input__success]:
                  !errors.document.error && errors.document.touched
              })}
              fontSize='lg'
              id='document'
              label='RUT'
              value={formValues.document}
            />
            {errors.document.dirty && errors.document.error ? (
              <Text
                color='error'
                customClassName={styles.text__error}
                fontSize='sm'
              >
                {errors.document.message}
              </Text>
            ) : null}
          </Column>
          <Column>
            <InputLabel
              {...customPropsInputForm}
              customInputClassName={classNames(styles.input__height, {
                [styles.input__error]: errors.name.error,
                [styles.input__success]:
                  !errors.name.error && errors.name.touched
              })}
              fontSize='lg'
              id='name'
              label='Nombre'
              value={formValues.name}
            />
            {errors.name.dirty && errors.name.error ? (
              <Text
                color='error'
                customClassName={styles.text__error}
                fontSize='sm'
              >
                {errors.name.message}
              </Text>
            ) : null}
          </Column>
          <Column>
            <InputLabel
              {...customPropsInputForm}
              customInputClassName={classNames(styles.input__height, {
                [styles.input__error]: errors.emailClient.error,
                [styles.input__success]:
                  !errors.emailClient.error && errors.emailClient.touched
              })}
              fontSize='lg'
              id='emailClient'
              label='Correo'
              value={formValues.emailClient}
            />
            {errors.emailClient.dirty && errors.emailClient.error ? (
              <Text
                color='error'
                customClassName={styles.text__error}
                fontSize='sm'
              >
                {errors.emailClient.message}
              </Text>
            ) : null}
          </Column>
          <Column>
            <InputLabel
              {...customPropsInputForm}
              customInputClassName={classNames(styles.input__height, {
                [styles.input__error]: errors.nameCompany.error,
                [styles.input__success]:
                  !errors.nameCompany.error && errors.nameCompany.touched
              })}
              fontSize='lg'
              id='nameCompany'
              label='Empresa'
              value={formValues.nameCompany}
            />
            {errors.nameCompany.dirty && errors.nameCompany.error ? (
              <Text
                color='error'
                customClassName={styles.text__error}
                fontSize='sm'
              >
                {errors.nameCompany.message}
              </Text>
            ) : null}
          </Column>
          <Row>
            <InputLabel
              {...customPropsInputForm}
              customClassName={styles.input__code}
              disabled
              fontSize='lg'
              id='code'
              inputWidth='25%'
              label='Codigo'
              onClickIcon={() => {
                return
              }}
              showXIcon={false}
              value={formValues.code}
            />
            <Column>
              <InputLabel
                {...customPropsInputForm}
                customInputClassName={classNames(styles.input__height, {
                  [styles.input__error]: errors.phone.error,
                  [styles.input__success]:
                    !errors.phone.error && errors.phone.touched
                })}
                fontSize='lg'
                id='phone'
                label='Telefono'
                value={formValues.phone}
              />
              {errors.phone.dirty && errors.phone.error ? (
                <Text
                  color='error'
                  customClassName={styles.text__error}
                  fontSize='sm'
                >
                  {errors.phone.message}
                </Text>
              ) : null}
            </Column>
          </Row>
          <Column>
            <InputLabel
              {...customPropsInputForm}
              customInputClassName={classNames(styles.input__height, {
                [styles.input__error]: errors.totalGiftcards.error,
                [styles.input__success]:
                  !errors.totalGiftcards.error && errors.totalGiftcards.touched
              })}
              fontSize='lg'
              id='totalGiftcards'
              label='Cantidad a cotizar'
              value={formValues.totalGiftcards}
            />
            {errors.totalGiftcards.dirty && errors.totalGiftcards.error ? (
              <Text
                color='error'
                customClassName={styles.text__error}
                fontSize='sm'
              >
                {errors.totalGiftcards.message}
              </Text>
            ) : null}
          </Column>
          <Column>
            <InputLabel
              {...customPropsInputForm}
              customInputClassName={classNames(styles.input__height, {
                [styles.input__error]: errors.valueGiftcard.error,
                [styles.input__success]:
                  !errors.valueGiftcard.error && errors.valueGiftcard.touched
              })}
              fontSize='lg'
              id='valueGiftcard'
              label='Monto individual'
              value={formValues.valueGiftcard}
            />
            {errors.valueGiftcard.dirty && errors.valueGiftcard.error ? (
              <Text
                color='error'
                customClassName={styles.text__error}
                fontSize='sm'
              >
                {errors.valueGiftcard.message}
              </Text>
            ) : null}
          </Column>
          <Column>
            <InputLabel
              {...customPropsInputForm}
              customInputClassName={classNames(styles.input__height, {
                [styles.input__error]: errors.message.error,
                [styles.input__success]:
                  !errors.message.error && errors.message.touched
              })}
              fontSize='lg'
              id='message'
              label='Mensaje'
              value={formValues.message}
            />
            {errors.message.dirty && errors.message.error ? (
              <Text
                color='error'
                customClassName={styles.text__error}
                fontSize='sm'
              >
                {errors.message.message}
              </Text>
            ) : null}
          </Column>
        </Column>
      </Row>
      {fetchError && (
        <ErrorToast
          isMobile={isMobile}
          message='Ha ocurrido un problema al enviar la cotización.'
          onClick={() => {
            setFetchError(false)
            onApply()
          }}
          positionContainer='fixed'
          setFetchError={setFetchError}
          top='64px'
        />
      )}
    </>
  )
}
