import { useEffect, useState } from 'react'
import classNames from 'classnames'
import {
  Column,
  Container,
  Dropdown,
  InputLabel,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { giftcardFieldsValidation, transformRut } from 'shared/helpers'
import styles from './FormInput.module.css'

interface FormInputProps {
  dropdownValues?: string[]
  label: string
  index?: string
  keyName?: string
  onValidation?: (value?) => void
  onValidDropdown?: (value?) => void
  onValidText?: (key?, value?, index?) => void
  type: 'dropdown' | 'text' | 'email' | 'phone'
}

export const FormInput = ({
  dropdownValues,
  label,
  index,
  keyName,
  onValidation,
  onValidDropdown,
  onValidText,
  type
}: FormInputProps) => {
  const [firstUse, setFirstUse] = useState(false)
  const [value, setValue] = useState(null)
  const [isValid, setIsValid] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)

  const handleChange = (newValue) => {
    const value = keyName === 'rut' ? transformRut(newValue) : newValue
    const validation = giftcardFieldsValidation(
      keyName,
      value,
      setErrorMessage,
      label
    )
    !firstUse && setFirstUse(true)
    setValue(type === 'dropdown' ? newValue : newValue.target.value)
    setIsValid(validation)
  }

  const handleCleanInput = () => {
    setValue('')
    !errorMessage && setFirstUse(false)
    setIsValid(false)
  }

  useEffect(() => {
    if ((onValidDropdown || onValidText) && isValid) {
      type === 'dropdown'
        ? onValidDropdown(value)
        : onValidText(keyName, value, index)
    }
  }, [value])

  useEffect(() => {
    isValid !== null &&
      onValidation &&
      onValidation({
        [`${keyName}${index ? `-${index}` : ''}`]: isValid
      })
  }, [isValid])

  if (type === 'dropdown')
    return (
      <Column>
        <Container
          className={classNames(styles['input_dropdown'], {
            [styles['input_dropdown--active']]: !value || value === label,
            [styles['input_container--error']]:
              value === label && firstUse && errorMessage,
            [styles['input_container--success']]:
              value !== label && firstUse && !errorMessage
          })}
        >
          {value && value !== label && (
            <Container className={classNames(styles['input_label'])}>
              {label}
            </Container>
          )}

          <Dropdown
            maxOptions={10}
            onChange={(value) => {
              handleChange(value)
            }}
            options={dropdownValues}
            placeholder={label}
            value={value}
          />
        </Container>
        {errorMessage && (
          <Text
            color='error'
            customClassName={styles['text__error']}
            fontSize='2xs'
          >
            {errorMessage}
          </Text>
        )}
      </Column>
    )
  return (
    <Column>
      <Column
        className={classNames(styles['input_container'], {
          [styles['input_container--error']]: firstUse && errorMessage,
          [styles['input_container--success']]: firstUse && !errorMessage
        })}
      >
        {keyName === 'phone' && (
          <Column
            className={classNames(
              styles['input_phone'],
              styles['input_container--success']
            )}
          >
            <Text
              customClassName={classNames(styles['input_label'])}
              fontSize='md'
            >
              Código
            </Text>
            <Text fontSize='md'>+569</Text>
          </Column>
        )}
        <InputLabel
          fontSize='lg'
          height='44px'
          id={label}
          inputWidth='100%'
          label={label}
          onChange={handleChange}
          onClickIcon={handleCleanInput}
          value={value}
        />
      </Column>
      {errorMessage && (
        <Text
          color='error'
          customClassName={styles['text__error']}
          fontSize='2xs'
        >
          {errorMessage}
        </Text>
      )}
    </Column>
  )
}
