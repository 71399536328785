import { Column, Divider, Row, Text } from '@smu-chile/pkg-unimarc-components'
import { Card } from 'components/Card'
import styles from '../balance.module.css'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import classNames from 'classnames'

export interface BalanceResumeProps {
  isMobile?: boolean
  balance: string
  expirationDate: string
  status: string
}

export const BalanceResume = ({
  isMobile,
  balance,
  expirationDate,
  status
}: BalanceResumeProps) => {
  return (
    <Card
      fontWeight='regular'
      height='386.51px'
      title='Saldo disponible'
      width={isMobile ? '100%' : '50%'}
    >
      <Row justifyContent='center'>
        <Text
          customClassName={styles.bigTitle}
          fontWeight='semibold'
        >
          {balance && `$${parseFloat(balance).toLocaleString('de-DE')}`}
        </Text>
      </Row>
      <Divider
        color={getGlobalStyle('--color-neutral-gray-divider')}
        thickness={1}
        verticalMargin={20}
        width={100}
      />
      <Row
        border={`1px solid ${getGlobalStyle('--color-neutral-gray-light')}`}
        borderRadius='12px'
        margin='32px 0 16px 0'
        padding='10px 8px 10px 8px'
      >
        <Column
          alignItems='center'
          justifyContent='center'
          minHeight={'28px'}
          width={'50%'}
        >
          <Text>Vigencia</Text>
        </Column>
        <Column
          alignItems='center'
          justifyContent='center'
          minHeight={'28px'}
          width={'50%'}
        >
          <Text
            color='gray-light'
            fontWeight='regular'
          >
            {expirationDate &&
              `${new Date(expirationDate).toLocaleDateString('es-ES', {
                year: '2-digit',
                month: '2-digit',
                day: '2-digit'
              })}`}
          </Text>
        </Column>
      </Row>
      <Row
        border={`1px solid ${getGlobalStyle('--color-neutral-gray-light')}`}
        borderRadius='12px'
        padding='10px 8px 10px 8px'
      >
        <Column
          alignItems='center'
          justifyContent='center'
          minHeight={'28px'}
          width={'50%'}
        >
          <Text>Estado</Text>
        </Column>
        <Column
          alignItems='center'
          justifyContent='center'
          minHeight={'28px'}
          width={'50%'}
        >
          <Text
            customClassName={classNames({
              [styles.successTitle]: status == 'Activa',
              [styles.errorTitle]: status != 'Activa'
            })}
            fontWeight='regular'
          >
            {status}
          </Text>
        </Column>
      </Row>
    </Card>
  )
}
