import Image from 'next/image'
import { Picture, Row } from '@smu-chile/pkg-unimarc-components'
import { Card } from 'components/Card'

export interface GiftcardImageProps {
  isMobile?: boolean
}

export const GiftcardImage = ({ isMobile }: GiftcardImageProps) => {
  return (
    <Card
      fontWeight='regular'
      title='Mi Giftcard'
      width={isMobile ? '100%' : '50%'}
    >
      <Row
        justifyContent='center'
        padding='0 0 38px 0'
      >
        <Row
          alignItems='center'
          borderRadius='12px'
          justifyContent='center'
          maxHeight='260px'
          maxWidth={isMobile ? '100%' : '518px'}
          minHeight='260px'
          minWidth={isMobile ? '100%' : '518px'}
        >
          <Row
            maxHeight='260px'
            maxWidth='400px'
          >
            <Picture
              height='260px'
              nextImage={Image}
              src={
                'https://images.ctfassets.net/un6yvtd6uq5z/1rErDakfCXWRHOjISuaKcx/3b09b5a64a2d4264d01b7beaa8987b34/type_image__option_01.png'
              }
              width={isMobile ? '100%' : '400px'}
            />
          </Row>
        </Row>
      </Row>
    </Card>
  )
}
