import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import {
  Button,
  Column,
  Row,
  Text,
  ToggleSwitch,
  AccordionV2,
  HoverTooltip,
  Icon
} from '@smu-chile/pkg-unimarc-components'
import { isValidArrayWithData } from '@smu-chile/pkg-unimarc-hooks'
import { useRouter } from 'next/router'
import { v4 as uuid } from 'uuid'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import styles from '../giftcard.module.css'
import { QuotationContent } from 'components/Home/QuotationContent'
import { FormInput } from 'components/FormInput'
import {
  checkGiftcardFormValidation,
  initialState,
  setValitadionForm,
  updateBeneficiaryInfo,
  validationMultipleState,
  validationSingleState
} from 'shared/helpers'
import { IFormValues } from 'shared/utils/hooks'
import { IFormValidations } from 'shared/interfaces/IFormValidations'
import { GIFTCARDS } from 'shared/utils/contants'
import { IGiftcardValues } from 'shared/interfaces/IGiftcardValues'

type FormGiftcardProps = {
  dataDropdown?: [IGiftcardValues]
  isMobile?: boolean
  isSingleGiftcard: boolean
  setIsSingleGiftcard: Dispatch<SetStateAction<boolean>>
}

export const FormGiftcard = ({
  dataDropdown,
  isMobile,
  isSingleGiftcard,
  setIsSingleGiftcard
}: FormGiftcardProps) => {
  const router = useRouter()
  const [rowKeys, setRowKeys] = useState<Record<string, string>[]>([])
  const [showModal, setShowModal] = useState(false)
  const [formData, setFormData] = useState<IFormValues>(initialState)
  const [validationData, setValidationData] = useState<IFormValidations>(
    validationSingleState
  )
  const [isValid, setIsValid] = useState(false)

  const dropdownValues = Object.entries(dataDropdown).map((entry) => {
    return entry?.[1]?.['label']
  })

  const classToggleSwitch = {
    true: styles['toogle__giftcard-active'],
    false: styles['toogle__giftcard-inactive']
  }

  const handleSwitchForm = () => {
    setIsSingleGiftcard(!isSingleGiftcard)
    setFormData(!isSingleGiftcard ? initialState : {})
    if (!isSingleGiftcard) {
      setRowKeys([])
      setValidationData(validationSingleState)
    } else {
      setValidationData(validationMultipleState)
    }
  }

  const onClick = () => {
    if (isValid) {
      localStorage.setItem('giftcardForm', JSON.stringify(formData))
      setValidationData(validationSingleState)
      router.push('/cart')
    }
  }

  const handleChangeQuantity = (quantity?) => {
    const rows = Array.from({ length: Number(quantity) }, () => {
      return {
        name: '',
        email: '',
        id: uuid()
      }
    })
    setRowKeys(rows)
    setFormData({
      ...formData,
      giftCardQuantity: quantity,
      beneficiaries: [...rows]
    })
  }

  const handleChangeAmount = (amount?) => {
    setFormData({ ...formData, giftCardAmount: amount })
  }

  const handleChangeUserInfo = (key?, value?, index?) => {
    setFormData(updateBeneficiaryInfo(formData, key, index, value))
  }

  const handleFormValidation = (value?) => {
    const key = Object.keys(value)[0]
    key !== 'undefined' &&
      setValidationData(setValitadionForm(validationData, value))
  }

  useEffect(() => {
    if (!isSingleGiftcard) {
      const validationRows = Array.from(
        { length: rowKeys?.length ? Number(rowKeys.length) : 0 },
        () => {
          return {
            name: false,
            email: false
          }
        }
      )
      setValidationData({
        ...validationData,
        beneficiaries: [...validationRows]
      })
    }
  }, [rowKeys])

  useEffect(() => {
    if (isSingleGiftcard) {
      if (!isValidArrayWithData(formData.beneficiaries)) return
      if (!formData.beneficiaries[0].name || !formData.beneficiaries[0].email) {
        setIsValid(false)
      } else {
        setIsValid(checkGiftcardFormValidation(validationData))
      }
    } else {
      setIsValid(checkGiftcardFormValidation(validationData))
    }
  }, [validationData])

  return (
    <Column
      border={`solid 0.3px ${getGlobalStyle('--color-neutral-gray-divider')}`}
      borderRadius='12px'
      gap='30px'
      margin={isMobile ? '2rem 0 0 0' : null}
      padding='16px'
      tagName='section'
      width={isMobile ? '100%' : '550px'}
    >
      <Row tagName='section'>
        <Text
          fontSize='xl'
          fontWeight='regular'
        >
          Personaliza tu regalo
        </Text>
      </Row>
      <Row tagName='section'>
        <ToggleSwitch
          customClass={styles.toogle__giftcard}
          customClassLeft={classToggleSwitch[`${isSingleGiftcard}`]}
          customClassRight={classToggleSwitch[`${!isSingleGiftcard}`]}
          fontWeight='medium'
          leftLabel='Una Giftcard'
          onClick={handleSwitchForm}
          rightLabel='Varias Giftcards'
          textPosition='center'
          value={isSingleGiftcard}
        />
      </Row>
      <Row tagName='section'>
        <Column
          gap='30px'
          tagName='article'
        >
          {isSingleGiftcard && (
            <Column gap={30}>
              <FormInput
                dropdownValues={dropdownValues}
                keyName='giftCardAmount'
                label='Monto'
                onValidDropdown={handleChangeAmount}
                onValidation={handleFormValidation}
                type='dropdown'
              />
              <FormInput
                index='0'
                keyName='name'
                label='Nombre del destinatario'
                onValidText={handleChangeUserInfo}
                onValidation={handleFormValidation}
                type='text'
              />
              <FormInput
                index='0'
                keyName='email'
                label='Correo del destinatario'
                onValidText={handleChangeUserInfo}
                onValidation={handleFormValidation}
                type='email'
              />
            </Column>
          )}
          {!isSingleGiftcard && (
            <Column gap={30}>
              <FormInput
                dropdownValues={GIFTCARDS.QUANTITY}
                keyName='giftCardQuantity'
                label='Cantidad'
                onValidDropdown={handleChangeQuantity}
                onValidation={handleFormValidation}
                type='dropdown'
              />
              <FormInput
                dropdownValues={dropdownValues}
                keyName='giftCardAmount'
                label='Monto'
                onValidDropdown={handleChangeAmount}
                onValidation={handleFormValidation}
                type='dropdown'
              />
            </Column>
          )}
          {!isSingleGiftcard && (
            <>
              {rowKeys.map((key, index) => {
                return (
                  <Row
                    key={key.id}
                    tagName='section'
                  >
                    <Column
                      margin={isMobile ? '1% 2% 0 0' : '.4% 0 0 0'}
                      tagName='article'
                      width='5%'
                    >
                      {isMobile ? (
                        <Icon
                          clickable='pointer'
                          color={getGlobalStyle('--color-neutral-gray-input')}
                          customSize={20}
                          name='Gift'
                        />
                      ) : (
                        <HoverTooltip
                          iconName='Gift'
                          iconSize={20}
                          infoTooltip='Aquí podrás escribir el nombre y correo de cada destinatario'
                        />
                      )}
                    </Column>
                    <Column tagName='article'>
                      <AccordionV2
                        body={
                          <Column
                            gap='20px'
                            padding='20px 28px 20px 0'
                          >
                            <FormInput
                              index={index.toString()}
                              keyName='name'
                              label='Nombre del destinatario'
                              onValidText={handleChangeUserInfo}
                              onValidation={handleFormValidation}
                              type='text'
                            />
                            <FormInput
                              index={index.toString()}
                              keyName='email'
                              label='Correo del destinatario'
                              onValidText={handleChangeUserInfo}
                              onValidation={handleFormValidation}
                              type='email'
                            />
                          </Column>
                        }
                        bottomDivider={false}
                        header={
                          <div>
                            <p>Giftcard {index + 1}</p>
                          </div>
                        }
                        isOpen
                      />
                    </Column>
                  </Row>
                )
              })}
            </>
          )}
          {!isSingleGiftcard && rowKeys.length == 10 && (
            <Row className={styles.infoContainer}>
              <Icon
                color={getGlobalStyle('--color-neutral-black')}
                margin='0 8px 0 0'
                name='Info'
                sizes='xs'
              />
              <span className={styles.infoContent}>
                Has alcanzado el máximo, para más de 10 Giftcards,{' '}
                <span
                  onClick={() => {
                    setShowModal(true)
                  }}
                >
                  cotiza aquí
                </span>
              </span>
            </Row>
          )}
          <Button
            background={getGlobalStyle(
              isValid
                ? '--color-background-razzmatazz'
                : '--color-background-pastel-gray'
            )}
            borderRadius='24px'
            customClassName={
              !isValid ? styles.button__cursor : 'button__form-pink'
            }
            label='Comprar'
            onClick={onClick}
            width='100%'
          />
        </Column>
      </Row>
      <QuotationContent
        isMobile={isMobile}
        setShowModal={setShowModal}
        showModal={showModal}
      />
    </Column>
  )
}
