import { useState } from 'react'
import { useRouter } from 'next/router'
import Image from 'next/future/image'
import Link from 'next/link'

import {
  Banners,
  BannersLayoutEnum,
  Column,
  Container,
  DataBody,
  Row,
  Title
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import {
  addToHomeData,
  clickedPromos,
  getContentfulImageCategories,
  useCategories,
  useContentful,
  useOnScreen,
  UseQueryOptions
} from '@smu-chile/pkg-unimarc-hooks'
import {
  BannerHomeObj,
  SendPromoDataImageBanner,
  SendPromoDataProps
} from 'shared/interfaces/Home'
import {
  getBannerImages,
  getBannerItemsToShow,
  getBannerShowDots,
  getContainerBackgroundColor,
  getContainerMaxWidth
} from './helpers'
import { DropdownWithRoundContent } from './helpers/DropdownWithRound'
import { LocalProductsBannerContent } from './helpers/LocalProductsBanner'

const BANNER_AND_TEXT = 'bannerAndText'

const clickBanner = (promosData, onClick = undefined) => {
  const { reference, promotionId, promotionName } = promosData

  if (reference && promotionId && promotionName) {
    sessionStorage.setItem(
      reference,
      JSON.stringify({
        promotionId: promotionId,
        promotionName: promotionName
      })
    )
  }
  promosData?.nombreCampaaGiftcard &&
    localStorage.setItem('gcCampaign', promosData.nombreCampaaGiftcard)
  clickedPromos(promosData)
  if (onClick) {
    onClick()
  }
}

const validateLayout = (
  data,
  isMobile,
  cfIndex,
  saleChannel,
  onClick = undefined
) => {
  const contentObject = isMobile ? 'imageMobile' : 'imageDesktop'
  const imageBanner = data?.['imageBanner']?.[0]

  if (data['layout'] === BANNER_AND_TEXT) {
    const newData = { ...data, image: data[contentObject] }
    delete newData?.imageDesktop
    delete newData?.imageMobile

    return {
      bannerAndText: newData
    }
  }

  if (data['layout'] == 'uno solo' && imageBanner) {
    const itemSaleChannels = imageBanner?.saleChannels
    if (itemSaleChannels && !itemSaleChannels.includes(saleChannel)) return null

    const banner: string =
      imageBanner?.[contentObject]?.['fields']?.['file']?.['url']
    return {
      bannerOnlyOne: {
        alt: imageBanner['alt'],
        banner,
        isMobile,
        onRedirect: () => {
          clickBanner({ ...imageBanner, locationId: cfIndex }, onClick)
        },
        url: imageBanner?.['reference']
      }
    }
  }

  if (
    ['grid', 'carousel', 'occasion of consumption', 'collage'].includes(
      data['layout']
    )
  ) {
    const nameType = ['grid', 'occasion of consumption', 'collage'].includes(
      data.layout
    )
      ? 'bannersGrid'
      : 'bannersCarousel'

    const bannerData: Array<{ img: string; url: string }> = data['imageBanner']
      ?.map((bannerInfo, index) => {
        const locationId = `${cfIndex} - ${index + 1}`

        return {
          alt: bannerInfo['alt'],
          creativeName: bannerInfo['creativeName'],
          creativeSlot: bannerInfo['creativeSlot'],
          img: bannerInfo[contentObject]['fields']['file']['url'],
          locationId: locationId,
          promotionId: bannerInfo['promotionId'],
          promotionName: bannerInfo['promotionName'],
          url: bannerInfo['reference'],
          saleChannels: bannerInfo['saleChannels'],
          onRedirect: () => {
            clickBanner({ ...bannerInfo, locationId })
          }
        }
      })
      .filter((banner) => {
        const saleChannels = banner['saleChannels']
        if (!saleChannels || saleChannels.includes(saleChannel)) return true
        return false
      })

    if (!bannerData.length) return null

    return { [nameType]: bannerData }
  }

  if (data['layout'] === '100% ours') {
    return {
      backgroundColor: data['color'],
      buttonLabel: data['textButton'],
      description: data['subtitle'],
      imagesSrcs: getBannerImages(data['imageBanner'], contentObject),
      onClick: () => {
        window.location.href = data['urlPath']
      },
      title: data['title']
    }
  }

  return null
}

const getCategoriesData = ({ categoriesApp, resultAisles }) => {
  const categoriesDataImage = getContentfulImageCategories(categoriesApp?.data)

  const data = Array.isArray(resultAisles?.data?.data)
    ? resultAisles.data.data.map((item) => {
        let url = item.url
        if (/\.com\.br(.+)/.test(url)) {
          url = `/category` + /\.com\.br(.+)/.exec(url)[1]
        }
        const categoryDataImage = categoriesDataImage?.find(
          ({ idCategory }) => {
            return idCategory === item.id
          }
        )
        return {
          href: url,
          image: categoryDataImage?.categoryImage?.file?.url,
          name: item.name,
          creativeName: categoryDataImage?.creativeName,
          creativeSlot: categoryDataImage?.creativeSlot,
          promotionId: categoryDataImage?.promotionId,
          promotionName: categoryDataImage?.promotionName
        }
      })
    : []

  return data
}

const getDropdownData = ({
  data,
  isMobile
}: {
  isMobile: boolean
  data: BannerHomeObj
}): DataBody[] => {
  return (
    Array.isArray(data?.['imageBanner']) &&
    data?.['imageBanner'].map((item) => {
      const typeOfImage = isMobile ? 'imageDesktop' : 'imageMobile'
      return {
        href: item?.['reference'],
        image: item?.[typeOfImage]?.fields?.file?.url,
        name: item?.['creativeName'],
        creativeName: item?.['creativeName'],
        creativeSlot: item?.['creativeSlot']
      }
    })
  )
}

const sendPromoData = ({ cfIndex, data, ref }: SendPromoDataProps) => {
  let promoData: SendPromoDataImageBanner = data

  if (data['layout'] == 'uno solo' && data['imageBanner'][0]) {
    const bannerImage = data['imageBanner'][0]
    promoData = {
      creativeName: bannerImage['creativeName'],
      creativeSlot: bannerImage['creativeSlot'],
      locationId: cfIndex,
      promotionId: bannerImage['promotionId'],
      promotionName: bannerImage['promotionName'],
      saleChannels: bannerImage['saleChannels']
    }
  }
  addToHomeData({ ref, promoData })
}

export interface PropsBannersWeb {
  cfIndex?: number
  data: BannerHomeObj
  isMobile: boolean
  isPriority?: boolean
  saleChannel?: string
  titleSizes?: object
  noPadding?: boolean
  onClick?: () => void
}

export const BannersWeb = ({
  cfIndex,
  data,
  isMobile,
  isPriority,
  saleChannel,
  titleSizes,
  noPadding,
  onClick
}: PropsBannersWeb) => {
  const router = useRouter()
  const [isOpenDropDown, setIsOpenDropDown] = useState<boolean>(false)
  const { elementRef, isIntersecting } = useOnScreen({ rootMargin: '0px' })
  const {
    elementRef: refProductsBanner,
    isIntersecting: isIntersectingProductsBanner
  } = useOnScreen({ rootMargin: '0px' })
  const { elementRef: refBanner, isIntersecting: isIntersectingBanner } =
    useOnScreen({ rootMargin: '0px' })
  const isCategories: boolean =
    data['layout'] == 'DropDownWithRound' && data['idReference'] == 'categories'
  const reactQueryCategories: UseQueryOptions = { enabled: isCategories }
  const resultAisles = useCategories({
    level: 2,
    reactQuery: reactQueryCategories
  })
  const categoriesApp = useContentful({
    options: {
      content_type: process?.env?.NEXT_PUBLIC_CATEGORIES_TYPE
    },
    reactQuery: reactQueryCategories
  })

  let dataDropdownRound: DataBody[] | [] = []

  if (data['layout'] == 'DropDownWithRound') {
    if (isCategories)
      dataDropdownRound = getCategoriesData({ resultAisles, categoriesApp })
    if (!isCategories) dataDropdownRound = getDropdownData({ data, isMobile })
  }

  const isAllowDropdownAction = dataDropdownRound.length > 4
  const bannerProps = validateLayout(
    data,
    isMobile,
    cfIndex,
    saleChannel,
    onClick
  )

  if (!bannerProps && !isCategories) return null

  const hasNoTitle = !(data.title || data.subtitle)
  const backgroundColor: string =
    data['layout'] === 'occasion of consumption'
      ? data['color']?.toString()
      : 'transparent'
  const DropdownWithRoundContentProps = {
    isIntersecting,
    dataBody: dataDropdownRound,
    handleChangeDropDown: () => {
      setIsOpenDropDown(!isOpenDropDown)
    },
    isOpen: isOpenDropDown,
    showMore: isAllowDropdownAction,
    title: data['title']?.toString(),
    linkWrapper: Link,
    cfIndex: cfIndex,
    onRedirect: () => {
      clickBanner({ ...dataDropdownRound })
    }
  }

  if (data['layout'] == 'DropDownWithRound') {
    return (
      <Column
        alignItems='center'
        backgroundColor={backgroundColor}
        maxWidth={getGlobalStyle('--width-max-desktop')}
        ref={elementRef}
      >
        <DropdownWithRoundContent {...DropdownWithRoundContentProps} />
      </Column>
    )
  }
  const LocalProductsBannerContentProps = {
    isIntersectingProductsBanner,
    backgroundColor: bannerProps.backgroundColor,
    buttonLabel: bannerProps.buttonLabel,
    description: bannerProps.description,
    imagesSrcs: bannerProps.imagesSrcs,
    linkWrapper: Link,
    onClick: bannerProps.onClick,
    title: bannerProps.title
  }
  if (data['layout'] === '100% ours') {
    return (
      <Column
        alignItems='center'
        ref={refProductsBanner}
      >
        <LocalProductsBannerContent {...LocalProductsBannerContentProps} />
      </Column>
    )
  }

  const {
    color: bannerBackgroundColor,
    expand: bannerExpand,
    infinite: bannerInfinite,
    itemsToShow: bannerItemsToShow,
    layout: bannerLayout,
    showDots: bannerShowDots,
    zoomIn: bannerZoomIn
  } = data as {
    color: string
    expand: boolean
    infinite: boolean
    itemsToShow: number
    layout: string
    showDots: boolean
    zoomIn: boolean
  }

  if (data?.imageBanner?.length === 0) {
    return null
  }

  const isBenefitGiftcardPath =
    router.asPath.split('/').at(-1) === 'beneficio_giftcard'

  const isBenefitsCarousel =
    data.layout === 'carousel' && /beneficios/.test(data.label.toLowerCase())
  const isBenefitsCarouselDesktop = isBenefitsCarousel && !isMobile

  return (
    <Container
      backgroundColor={
        hasNoTitle
          ? 'none'
          : getContainerBackgroundColor(bannerLayout, bannerBackgroundColor)
      }
      borderRadius={isMobile ? 'none' : '20px'}
      justifyContent='center'
      maxWidth={getContainerMaxWidth(bannerLayout, bannerExpand)}
      padding={
        noPadding
          ? undefined
          : hasNoTitle
          ? '0 12px'
          : isMobile
          ? '12px'
          : '20px'
      }
      ref={refBanner}
    >
      {isIntersectingBanner && (
        <Column
          alignItems='center'
          backgroundColor={backgroundColor}
          borderRadius={isMobile ? 'none' : '20px'}
          maxWidth={getGlobalStyle('--width-max-desktop')}
        >
          {isBenefitGiftcardPath &&
          data['layout'] === 'collage' ? null : hasNoTitle ? null : (
            <Column
              padding={isMobile ? '0px 0px 12px 0px' : '0px 0px 24px 0px'}
            >
              <Row
                justifyContent={
                  isBenefitGiftcardPath ||
                  (isBenefitsCarouselDesktop && data?.imageBanner?.length <= 4)
                    ? 'center'
                    : undefined
                }
              >
                <Title
                  fontWeight={isBenefitGiftcardPath ? 'medium' : 'regular'}
                  headingLevel='h2'
                  {...titleSizes['h2']}
                  text={data['title']}
                />
              </Row>
              <Row
                justifyContent={
                  isBenefitGiftcardPath ||
                  (isBenefitsCarouselDesktop && data?.imageBanner?.length <= 4)
                    ? 'center'
                    : undefined
                }
              >
                <Title
                  fontWeight='regular'
                  headingLevel='h3'
                  {...titleSizes['h3']}
                  text={data['subtitle']}
                />
              </Row>
            </Column>
          )}

          <Row overflow='hidden'>
            <Banners
              catchPromoData={(promoData?: {
                ref?: object
                banner?: object
              }) => {
                sendPromoData({
                  cfIndex: `${cfIndex}`,
                  data: promoData.banner || data,
                  ref: promoData?.ref || promoData
                })
              }}
              imageBanner={
                data['layout'] === 'carousel'
                  ? data['imageBanner']
                  : data['imageBanner'] && data['imageBanner'][0]
              }
              infinite={bannerInfinite}
              isGiftcard
              isHover={
                data['layout'] == 'collage' || data['layout'] == 'carousel'
              }
              isPriority={isPriority}
              itemsToShow={getBannerItemsToShow(
                bannerLayout,
                bannerItemsToShow
              )}
              layout={data['layout'] as BannersLayoutEnum}
              linkWrapper={Link}
              minHeight={data['height']?.toString()}
              nextImage={Image}
              saleChannel={saleChannel}
              showDots={getBannerShowDots(bannerLayout, bannerShowDots)}
              zoomIn={bannerZoomIn}
              {...bannerProps}
              showCarousel={false}
            />
          </Row>
        </Column>
      )}
    </Container>
  )
}
