export const formatNumber = (amount: number) => {
  let formatNumber: string | string[] = amount.toLocaleString('de-DE')
  if (amount < 0) {
    formatNumber = formatNumber.split('-')

    return `-$${formatNumber[1]}`
  }

  return `$${formatNumber}`
}
