import { normalizeString } from '../../shared/helpers'

const webSiteSchema = JSON.stringify({
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  name: 'Unimarc Online',
  url: 'https://www.unimarc.cl',
  potentialAction: {
    '@type': 'SearchAction',
    target: 'https://www.unimarc.cl/search?q={search_term_string}',
    'query-input': 'required name=search_term_string'
  }
})

const organizationSchema = JSON.stringify({
  '@context': 'https://schema.org',
  '@type': 'Corporation',
  name: 'Unimarc',
  alternateName: 'Supermercados Unimarc',
  url: 'https://www.unimarc.cl',
  logo: 'https://locales.unimarc.cl/wp-content/themes/unimarc/img/mobile/logo.png',
  sameAs: [
    'https://www.facebook.com/unimarc',
    'https://twitter.com/unimarc',
    'https://www.instagram.com/unimarc',
    'https://www.youtube.com/user/unimarccl'
  ]
})

const breadSchema = (categories, url, pageNumber) => {
  const categoryName = []
  const breadCrumbs = categories?.map?.((category, index) => {
    const normalizedCategory = normalizeString(category)
    categoryName.push(normalizedCategory)
    if (index === categories.length - 1 && pageNumber) {
      return {
        '@type': 'ListItem',
        position: index + 2,
        name: normalizedCategory,
        item: `${url}${categoryName.join('/')}?page=${pageNumber}`
      }
    }
    return {
      '@type': 'ListItem',
      position: index + 2,
      name: normalizedCategory,
      item: `${url}${categoryName.join('/')}`
    }
  })

  if (breadCrumbs.length === 0) {
    return null
  }

  breadCrumbs?.unshift({
    '@type': 'ListItem',
    position: 1,
    name: 'Inicio',
    item: 'https://www.unimarc.cl'
  })

  return JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: breadCrumbs
  })
}

const productSchema = (productInfo, canonicalUrl) => {
  return JSON.stringify({
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: productInfo?.name,
    image: productInfo?.images,
    description: productInfo?.description,
    sku: productInfo?.sku,
    brand: {
      '@type': 'Brand',
      name: productInfo?.brand
    },
    offers: {
      '@type': 'Offer',
      url: canonicalUrl,
      priceCurrency: 'CLP',
      price: productInfo?.price,
      availability: `https://schema.org/${productInfo?.availability}`
    }
  })
}

export { webSiteSchema, organizationSchema, breadSchema, productSchema }
