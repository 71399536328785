import { useState } from 'react'
import Image from 'next/future/image'

// Packages
import { Carousel, Container, Picture } from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { isValidArrayWithData } from '@smu-chile/pkg-unimarc-hooks'

import styles from './showcase.module.css'

interface IShowCaseProps {
  data: object[]
  isMobile: boolean
}

export const ShowCase = ({ isMobile, data }: IShowCaseProps) => {
  const [autoPlay, setAutoPlay] = useState(false)
  const contentObject = isMobile ? 'imageMobile' : 'imageDesktop'
  const containerProps = { maxWidth: '2800px' }

  const handleStart = () => {
    return setAutoPlay(false)
  }

  const handleEnd = () => {
    return setAutoPlay(true)
  }

  return (
    <>
      <Container
        justifyContent='center'
        margin={isMobile ? 'none' : '60px 0'}
      >
        <Carousel
          arrows={!isMobile}
          autoPlay={autoPlay}
          containerClass={styles.borderNone}
          containerProps={containerProps}
          deviceType={isMobile ? 'mobile' : 'desktop'}
          focusOnSelect
          infinite
          itemClass={styles.borderNone}
          opacityArrows
          pauseOnHover
          ssr
        >
          {isValidArrayWithData(data) &&
            data.map((item) => {
              const contentObjectSrc =
                item[contentObject]?.fields?.file?.url ??
                item['image']?.fields?.file?.url
              return (
                <Picture
                  height='100%'
                  key={item['label']}
                  nextImage={Image}
                  onTouchEnd={handleEnd}
                  onTouchStart={handleStart}
                  src={contentObjectSrc}
                  style={{
                    width: '100%',
                    height: '100%',
                    borderRadius: getGlobalStyle('--border-radius-none')
                  }}
                  title={item['image']?.fields?.title}
                  width='100%'
                />
              )
            })}
        </Carousel>
      </Container>
    </>
  )
}
