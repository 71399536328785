import { validateRut } from '@smu-chile/pkg-unimarc-components/utils'
import { useState } from 'react'

/**
 * Validates a RUT (Rol Único Tributario).
 *
 * @param {string} value - The RUT to be validated.
 * @returns {string} - An error message if the RUT is invalid, otherwise an empty string.
 */
export const rutValidator = (value) => {
  if (!value) {
    return 'RUT es requerido'
  }

  if (!validateRut(value)) {
    return 'Por favor ingresa un RUT válido'
  }

  return ''
}

/**
 * Validates an email address.
 *
 * @param {string} email - The email address to be validated.
 * @returns {string} - An error message if the email is invalid, otherwise an empty string.
 */
export const emailValidator = (email) => {
  if (!email) {
    return 'Correo es requerido'
  }

  if (!new RegExp(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/).test(email)) {
    return 'Por favor verifica el correo ingresado'
  }

  return ''
}

/**
 * Validates a name.
 *
 * @param {string} name - The name to be validated.
 * @returns {string} - An error message if the name is invalid, otherwise an empty string.
 */
export const nameValidator = (name: string) => {
  if (!name) {
    return 'Nombre es requerido'
  }

  if (name.length < 3) {
    return 'Por favor ingrese mínimo 3 caracteres'
  }

  if (!new RegExp(/^[a-zA-ZñÑ ]+$/).test(name)) {
    return 'Por favor escribe el nombre sin caracteres especiales'
  }

  return ''
}

/**
 * Validates a phone number.
 *
 * @param {string} phone - The phone number to be validated.
 * @returns {string} - An error message if the phone number is invalid, otherwise an empty string.
 */
export const phoneValidator = (phone) => {
  if (!phone) {
    return 'Telefono es requerido'
  }

  if (!new RegExp(/^[0-9]{8}$/).test(phone)) {
    return 'Por favor ingresa máximo 8 números'
  }

  return ''
}

/**
 * Validates a value for a gift card.
 *
 * @param {string} value - The value to be validated.
 * @returns {string} - An error message if the value is invalid, otherwise an empty string.
 */
export const totalGiftcardValidator = (value) => {
  if (!value) {
    return 'Este campo es requerido'
  }

  if (!new RegExp(/^[0-9]*$/).test(value)) {
    return 'Por favor escribe solo números'
  }

  return ''
}

/**
 * Checks if a value is required.
 *
 * @param {string} value - The value to be checked.
 * @returns {string} - An error message if the value is required and empty, otherwise an empty string.
 */
export const required = (value) => {
  if (!value) {
    return 'Este campo es requerido'
  }

  return ''
}

/**
 * Custom hook for form validation.
 *
 * @returns {Object} - An object with the `validateForm` function and the `errors` state.
 */
export const useFormValidator = () => {
  const [errors, setErrors] = useState({
    document: {
      dirty: true,
      touched: false,
      error: false,
      message: ''
    },
    name: {
      dirty: true,
      touched: false,
      error: false,
      message: ''
    },
    emailClient: {
      dirty: true,
      touched: false,
      error: false,
      message: ''
    },
    nameCompany: {
      dirty: true,
      touched: false,
      error: false,
      message: ''
    },
    phone: {
      dirty: true,
      touched: false,
      error: false,
      message: ''
    },
    message: {
      dirty: true,
      touched: false,
      error: false,
      message: ''
    },
    totalGiftcards: {
      dirty: true,
      touched: false,
      error: false,
      message: ''
    },
    valueGiftcard: {
      dirty: true,
      touched: false,
      error: false,
      message: ''
    }
  })

  const validatorFunctions = {
    document: rutValidator,
    emailClient: emailValidator,
    name: nameValidator,
    phone: phoneValidator,
    totalGiftcards: totalGiftcardValidator,
    valueGiftcard: totalGiftcardValidator,
    nameCompany: required,
    message: required
  }

  const validateForm = ({ form, field, errors, onChange }) => {
    let isValid = true

    const nextErrors = JSON.parse(JSON.stringify(errors))

    for (const f in form) {
      if (nextErrors[f]?.dirty && (field ? field === f : true)) {
        const validator = validatorFunctions[f]
        if (validator) {
          nextErrors[f].touched = true
          const message = validator(form[f])
          nextErrors[f].error = !!message
          nextErrors[f].message = message
          if (message) isValid = false
        }
      }
    }

    setErrors(nextErrors)

    onChange({ form, errors: nextErrors })

    return {
      isValid,
      errors: nextErrors
    }
  }

  return {
    validateForm,
    errors
  }
}
