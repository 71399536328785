import { useEffect, useState } from 'react'
import Image from 'next/image'
import {
  Backbone,
  Carousel,
  Column,
  HoverTooltip,
  Picture,
  Row,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { useContentful } from '@smu-chile/pkg-unimarc-hooks'
import styles from '../giftcard.module.css'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { GIFTCARDS } from 'shared/utils/contants'

type KindOfGiftcardProps = {
  isMobile?: boolean
  isSingleGiftcard?: boolean
}

export const KindOfGiftcard = ({
  isMobile,
  isSingleGiftcard
}: KindOfGiftcardProps) => {
  const [giffcardSelected, setGiffcardSelected] = useState(null)
  const [kindOfCards, setKindOfCards] = useState([])
  const kindOfGiffcard = useContentful({
    id_contentful: 'featureContentBannersWeb',
    options: {
      'sys.id': GIFTCARDS.COLLAGE,
      include: 2
    }
  })
  const isLoading =
    giffcardSelected === null ||
    kindOfGiffcard.isLoading ||
    kindOfGiffcard.isFetching

  const responsive = {
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1024
      },
      items: 4,
      partialVisibilityGutter: 10
    },
    mobile: {
      breakpoint: {
        max: 1024,
        min: 300
      },
      items: 3,
      partialVisibilityGutter: 5
    }
  }

  const handleChangeKindOfGiftCard = (event: React.MouseEvent) => {
    const target = event?.target as HTMLImageElement
    setGiffcardSelected(target?.src)
    const campaign = kindOfCards.find((gc) => {
      const targetUrl =
        target?.src?.split('/')?.[target?.src?.split('/')?.length - 1]
      const imageUrl =
        gc?.imageDesktop?.file?.url?.split('/')?.[
          gc?.imageDesktop?.file?.url?.split('/')?.length - 1
        ]
      return targetUrl === imageUrl
    })
    localStorage.setItem('gcCampaign', campaign?.campaign)
  }

  useEffect(() => {
    //This instance can be used to read a query param to a set of type previously selected
    if (kindOfGiffcard.isLoading || kindOfCards.length > 0) return
    const kindOfGiffcardData = []
    kindOfGiffcard?.data?.['items']?.[0]?.fields?.imageBanner?.forEach(
      (item) => {
        const newItem = Object.assign({}, item)
        const data = kindOfGiffcard?.data?.['includes']?.[
          item?.sys?.linkType
        ]?.find((include) => {
          return include.sys.id === item.sys.id
        })?.fields
        if (data) {
          for (const subItemAsset of ['imageDesktop', 'imageMobile']) {
            const linkType = data?.[subItemAsset]?.sys?.linkType
            const id = data?.[subItemAsset]?.sys?.id
            newItem[subItemAsset] = kindOfGiffcard.data?.['includes']?.[
              linkType
            ]?.find((image) => {
              return image.sys.id === id
            })?.fields
          }
          kindOfGiffcardData.push({
            ...newItem,
            campaign: data?.nombreCampaaGiftcard
          })
        }
      }
    )
    if (kindOfGiffcardData.length > 0) {
      setKindOfCards(kindOfGiffcardData)
      const campaign = localStorage.getItem('gcCampaign')
      if (campaign) {
        setGiffcardSelected(
          kindOfGiffcardData.find((gc) => {
            return gc.campaign === campaign
          }).imageDesktop.file.url
        )
      } else {
        setGiffcardSelected(kindOfGiffcardData[0].imageDesktop.file.url)
      }
    }
  }, [kindOfGiffcard.isLoading])

  return (
    <>
      {isMobile ? (
        <Column
          border={`solid 0.3px ${getGlobalStyle(
            '--color-neutral-gray-divider'
          )}`}
          borderRadius='12px'
          padding='16px'
          width='100%'
        >
          <Row
            justifyContent='between'
            padding='0 0 1px 0'
          >
            <Text
              fontSize='xl'
              fontWeight='regular'
            >
              Giftcard
            </Text>
          </Row>
          <Row
            justifyContent='center'
            padding='0 0 1px 0'
          >
            <Row
              alignItems='center'
              borderRadius='12px'
              justifyContent='center'
            >
              <Row>
                {isLoading ? (
                  <Backbone
                    borderRadius='12px'
                    height={260}
                  />
                ) : (
                  <Picture
                    height='260px'
                    nextImage={Image}
                    src={giffcardSelected}
                    width='100%'
                  />
                )}
              </Row>
            </Row>
          </Row>
          <Row>
            <Column>
              <Row alignItems='center'>
                <Text
                  customClassName={styles.label__margin}
                  fontWeight='regular'
                >
                  Elige otro motivo
                </Text>
                {!isSingleGiftcard && (
                  <HoverTooltip
                    iconSize={14}
                    infoTooltip='Elige un solo motivo para regalar varias Gift Cards.'
                  />
                )}
              </Row>
              <Row padding='16px 0'>
                <Carousel
                  autoPlay={false}
                  deviceType='desktop'
                  marginArrows='-10px'
                  opacityArrows
                  partialVisible={true}
                  responsive={responsive}
                  showDots={false}
                  ssr
                >
                  {isLoading &&
                    Array(5)
                      .fill(1)
                      .map((_, index) => {
                        return (
                          <Row
                            borderRadius='100px'
                            key={index}
                          >
                            <Backbone
                              borderRadius='100px'
                              height={100}
                            />
                          </Row>
                        )
                      })}
                  {!isLoading &&
                    kindOfCards.length > 0 &&
                    kindOfCards?.map((card) => {
                      const src = card?.['imageDesktop'].file.url
                      return (
                        <Picture
                          borderRadius='100px'
                          height='100px'
                          key={src}
                          objectFit='cover'
                          onClick={handleChangeKindOfGiftCard}
                          src={src}
                          style={{ borderRadius: 100, objectFit: 'cover' }}
                          width='100px'
                        />
                      )
                    })}
                </Carousel>
              </Row>
              <Row justifyContent='center'>
                <Text
                  color='gray-light'
                  fontSize='md'
                  fontWeight='regular'
                >
                  Haz clic en un motivo para visualizarlo arriba
                </Text>
              </Row>
            </Column>
          </Row>
        </Column>
      ) : (
        <Column
          border={`solid 0.3px ${getGlobalStyle(
            '--color-neutral-gray-divider'
          )}`}
          borderRadius='12px'
          padding='16px'
          width='518px'
        >
          <Row
            justifyContent='between'
            padding='0 0 32px 0'
          >
            <Text
              fontSize='xl'
              fontWeight='regular'
            >
              Giftcard
            </Text>
          </Row>
          <Row
            justifyContent='center'
            padding='0 0 38px 0'
          >
            <Row
              alignItems='center'
              borderRadius='12px'
              justifyContent='center'
              maxHeight='260px'
              maxWidth='518px'
              minHeight='260px'
              minWidth='518px'
            >
              <Row
                maxHeight='260px'
                maxWidth='400px'
              >
                {isLoading ? (
                  <Backbone
                    borderRadius='12px'
                    height={260}
                  />
                ) : (
                  <Picture
                    height='260px'
                    nextImage={Image}
                    src={giffcardSelected}
                    width='400px'
                  />
                )}
              </Row>
            </Row>
          </Row>
          <Row>
            <Column>
              <Row alignItems='center'>
                <Text
                  customClassName={styles.label__margin}
                  fontWeight='regular'
                >
                  Elige otro motivo
                </Text>
                {!isSingleGiftcard && (
                  <HoverTooltip
                    iconSize={14}
                    infoTooltip='Elige un solo motivo para regalar varias Gift Cards.'
                  />
                )}
              </Row>
              <Row padding='16px 0'>
                <Carousel
                  autoPlay={false}
                  deviceType='desktop'
                  marginArrows='-10px'
                  opacityArrows
                  partialVisible={true}
                  responsive={responsive}
                  showDots={false}
                  ssr
                >
                  {isLoading &&
                    Array(5)
                      .fill(1)
                      .map((_, index) => {
                        return (
                          <Row
                            borderRadius='100px'
                            key={index}
                            maxWidth='100px'
                          >
                            <Backbone
                              borderRadius='100px'
                              height={100}
                            />
                          </Row>
                        )
                      })}
                  {!isLoading &&
                    kindOfCards.length > 0 &&
                    kindOfCards?.map((card) => {
                      const src = card?.['imageDesktop'].file.url
                      return (
                        <Picture
                          borderRadius='100px'
                          height='100px'
                          key={src}
                          objectFit='cover'
                          onClick={handleChangeKindOfGiftCard}
                          src={src}
                          style={{ borderRadius: 100, objectFit: 'cover' }}
                          width='100px'
                        />
                      )
                    })}
                </Carousel>
              </Row>
              <Row justifyContent='center'>
                <Text
                  color='gray-light'
                  fontSize='md'
                  fontWeight='regular'
                >
                  Haz clic en un motivo para visualizarlo arriba
                </Text>
              </Row>
            </Column>
          </Row>
        </Column>
      )}
    </>
  )
}
