import { IMAGES } from 'shared/utils/contants'
import { Column, Picture, Row, Text } from '@smu-chile/pkg-unimarc-components'
import { useEffect, useState } from 'react'
import { useContentful } from '@smu-chile/pkg-unimarc-hooks'
import styles from '../home.module.css'

export const SuccessContent = () => {
  const [email, setEmail] = useState('')

  const orangeAsset = useContentful({
    id_contentful: 'orange-image',
    options: {
      'sys.id': IMAGES.ORANGE
    },
    type: 'assets'
  })
  const orangeImage = orangeAsset?.data?.['items']?.[0]?.fields?.file?.url

  useEffect(() => {
    const email = localStorage.getItem('clientEmail')
    setEmail(email)
  }, [email])

  return (
    <Column alignItems='center'>
      <Row justifyContent='center'>
        <Picture
          alt='cake'
          height='211px'
          src={orangeImage}
          width='262px'
        />
      </Row>
      <Row
        justifyContent='center'
        margin='0 0 16px 0'
      >
        <Text
          fontSize='2xl'
          fontWeight='semibold'
          textAlign='center'
        >
          ¡Pronto sabrás de nosotros!
        </Text>
      </Row>
      <Row
        justifyContent='center'
        margin='0 0 16px 0'
      >
        <p className={styles.text__desc}>
          Tu cotización se envió con éxito. Enviaremos una copia a tu correo{' '}
          <span>{email}</span>
        </p>
      </Row>
    </Column>
  )
}
