import { IMAGES } from 'shared/utils/contants'
import { useEffect, useMemo, useState } from 'react'
import { Context as ResponsiveContext } from '@smu-chile/pkg-unimarc-components/reactResponsive'
import { Column, Container, Row } from '@smu-chile/pkg-unimarc-components'
import { GiftcardImage } from './GiftcardImage'
import { BalanceResume } from './BalanceResume'
import { Transactions } from './Transactions'
import { BannersWeb } from 'components/Home/BannersWeb'
import {
  ContentfulResponse,
  IContentfulResponse,
  useContentful,
  useMobile
} from '@smu-chile/pkg-unimarc-hooks'
import {
  BigScreen,
  SmallScreen,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { useRouter } from 'next/router'

export interface MovementsProps {
  movementDate?: string
  movementType?: string
  movementValue?: number
  local?: string
}

export interface BalanceDataProps {
  balance?: string
  expirationDate?: string
  status?: string
  movements?: MovementsProps[]
}

export const Balance = () => {
  const [bannerData, setBannerData] = useState({})
  const [balanceData, setBalanceData] = useState<BalanceDataProps>()
  const { innerWidth } = useMobile()
  const router = useRouter()

  const result = useContentful({
    id_contentful: 'imageBannerWeb',
    options: {
      'sys.id': IMAGES.BANNER_BALANCE
    }
  })

  const isMobile = innerWidth < 1280

  const withProvider: number = useMemo(() => {
    const hasInnerWith: number = isMobile && !innerWidth ? 500 : 1400
    return innerWidth ? innerWidth : hasInnerWith
  }, [innerWidth, isMobile])

  useEffect(() => {
    const populatedData = new ContentfulResponse(
      result?.data as IContentfulResponse
    )
      ?.populateEntries()
      ?.getResponse()

    const contentBanner = {
      height: 100,
      imageBanner: populatedData?.items,
      label: '',
      layout: 'uno solo'
    }

    setBannerData(contentBanner)
  }, [result.isLoading])

  useEffect(() => {
    const data = localStorage.getItem('balanceData')
    setBalanceData(data ? JSON.parse(data) : undefined)
  }, [router?.query])

  return (
    <ResponsiveContext.Provider value={{ width: withProvider }}>
      <BigScreen>
        <Container
          backgroundColor={getGlobalStyle('--color-base-white')}
          isWrap
          margin='6rem auto 6rem auto'
          maxWidth='1120px'
        >
          <Row
            gap='20px'
            justifyContent='between'
          >
            <GiftcardImage />
            <BalanceResume
              balance={balanceData?.balance}
              expirationDate={balanceData?.expirationDate}
              status={balanceData?.status}
            />
          </Row>
          <Row margin='32px 0'>
            {bannerData?.['imageBanner'] && (
              <BannersWeb
                data={bannerData}
                isMobile={isMobile}
                noPadding
              />
            )}
          </Row>
          <Transactions items={balanceData?.movements} />
        </Container>
      </BigScreen>
      <SmallScreen>
        <Container
          backgroundColor={getGlobalStyle('--color-base-white')}
          isWrap
          margin='6rem auto 0 auto'
          maxWidth='1120px'
          padding='1rem'
        >
          <Row
            gap='20px'
            justifyContent='between'
          >
            <Column>
              <Row margin='0 0 20px 0'>
                <GiftcardImage isMobile={isMobile} />
              </Row>
              <Row>
                <BalanceResume
                  balance={balanceData?.balance}
                  expirationDate={balanceData?.expirationDate}
                  isMobile={isMobile}
                  status={balanceData?.status}
                />
              </Row>
              <Row margin='30px 0'>
                {bannerData?.['imageBanner'] && (
                  <BannersWeb
                    data={bannerData}
                    isMobile={isMobile}
                    noPadding
                  />
                )}
              </Row>
              <Transactions
                isMobile={isMobile}
                items={balanceData?.movements}
              />
            </Column>
          </Row>
        </Container>
      </SmallScreen>
    </ResponsiveContext.Provider>
  )
}
