import {
  Backbone,
  Column,
  Row,
  Spacer
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'

export const BackboneShowCaseWeb = () => {
  return (
    <>
      <Spacer.Horizontal size={32} />
      <BigScreen>
        <>
          <Column
            justifyContent='between'
            maxWidth='1120px'
          >
            <Column>
              <Row maxWidth='1120px'>
                <Backbone
                  borderRadius='8px'
                  height={450}
                />
              </Row>
              <Spacer.Horizontal size={12} />
            </Column>
          </Column>
        </>
      </BigScreen>

      <SmallScreen>
        <>
          <Column
            justifyContent='between'
            maxWidth='1120px'
          >
            <Column>
              <Row maxWidth='1120px'>
                <Backbone
                  borderRadius='8px'
                  height={450}
                />
              </Row>
              <Spacer.Horizontal size={12} />
            </Column>
          </Column>
        </>
      </SmallScreen>
      <Spacer.Horizontal size={24} />
    </>
  )
}
