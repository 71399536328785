import Link from 'next/link'
import {
  Breadcrumbs,
  Container,
  Row,
  Column
} from '@smu-chile/pkg-unimarc-components'
import { Context as ResponsiveContext } from '@smu-chile/pkg-unimarc-components/reactResponsive'
import {
  getGlobalStyle,
  BigScreen,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { FormCart } from './FormCart'
import { OrderSummary } from './OrderSummary'
import { useState, useMemo } from 'react'
import { TermsModal } from '../TermsModal'
import { BannerHome } from 'shared/interfaces/Home'
import { IPaymentData } from 'shared/interfaces/IFormValidations'
import { cleanLocalStorage } from 'shared/helpers'
import { IGiftcardValues } from 'shared/interfaces/IGiftcardValues'

const breadcrumbData = [
  {
    label: 'Inicio',
    url: '/',
    oneClick: () => {
      return
    }
  },
  {
    label: 'Giftcard',
    url: '/giftcard',
    oneClick: () => {
      return
    }
  },
  {
    label: 'Mi Compra',
    url: '/cart'
  }
]

export type ErrorsProps = {
  dirty: boolean
  error: boolean
  message: string
  touched: boolean
}

export type FormProps = {
  email: string
  phone: number
  name: string
}

export type ValuesProps = {
  errors: {
    name: ErrorsProps
    email: ErrorsProps
    phone: ErrorsProps
  }
  form: FormProps
}

export interface CartProp {
  data: BannerHome
  dataDropdown?: [IGiftcardValues]
}

export const Cart = ({ data, dataDropdown }: CartProp) => {
  const [values, setValues] = useState<IPaymentData>()
  const [showModal, setShowModal] = useState(false)
  const [value, setValue] = useState('termsAndConditions')
  const [isValid, setIsValid] = useState(false)

  const { innerWidth } = useMobile()

  const isMobile = innerWidth < 1280
  const withProvider: number = useMemo(() => {
    const hasInnerWith: number = isMobile && !innerWidth ? 500 : 1400
    return innerWidth ? innerWidth : hasInnerWith
  }, [innerWidth, isMobile])

  const onChange = (newValues) => {
    setValues(newValues)
  }

  return (
    <ResponsiveContext.Provider value={{ width: withProvider }}>
      <BigScreen>
        <Container
          backgroundColor={getGlobalStyle('--color-base-white')}
          isWrap
          maxWidth='1120px'
        >
          <TermsModal
            data={data[value]?.['content']}
            padding='1.25rem 2.75rem 1rem 2.75rem'
            setShowModal={setShowModal}
            showModal={showModal}
            title={data[value]?.title}
          />
          <Row padding='16px 0 32px 0'>
            <Breadcrumbs
              afterClick={cleanLocalStorage}
              changeLinkColor
              colorActiveLink='razzmatazz'
              customDivider='>'
              dividerClassName='divider__color'
              firstLinkColor='brightGrey'
              linkWrapper={Link}
              links={breadcrumbData}
            />
          </Row>
          <Row
            gap='20px'
            justifyContent='between'
          >
            <Column>
              <FormCart
                handleIsValid={setIsValid}
                onChange={onChange}
              />
            </Column>
            <OrderSummary
              dataDropdown={dataDropdown}
              isValid={isValid}
              paymentData={values}
              setShowModal={setShowModal}
              setValue={setValue}
            />
          </Row>
        </Container>
      </BigScreen>
      <SmallScreen>
        <Container
          backgroundColor={getGlobalStyle('--color-base-white')}
          isWrap
          margin='6rem auto 0 auto'
          maxWidth='1120px'
          padding='1rem'
        >
          <TermsModal
            data={data[value]?.['content']}
            isMobile={isMobile}
            padding='1.25rem 1rem 1rem 1rem'
            setShowModal={setShowModal}
            showModal={showModal}
            title={data[value]?.title}
          />
          <Row
            gap='20px'
            justifyContent='between'
          >
            <Column>
              <FormCart
                handleIsValid={setIsValid}
                isMobile={isMobile}
                onChange={onChange}
                width='100%'
              />
              <OrderSummary
                dataDropdown={dataDropdown}
                isMobile={isMobile}
                isValid={isValid}
                paymentData={values}
                setShowModal={setShowModal}
                setValue={setValue}
                width='100%'
              />
            </Column>
          </Row>
        </Container>
      </SmallScreen>
    </ResponsiveContext.Provider>
  )
}
