import { formatRut } from '@smu-chile/pkg-unimarc-components/utils'

/**
 * Cleans a name by removing invalid characters.
 *
 * @param {string} name - The name to be cleaned.
 * @returns {string} - The cleaned name.
 */
function cleanName(name: string): string {
  return typeof name === 'string'
    ? name.replace(
        /[^A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s.'-]*/g,
        ''
      )
    : ''
}

/**
 * Cleans a number by removing non-digit characters.
 *
 * @param {string} number - The number to be cleaned.
 * @returns {string} - The cleaned number.
 */
function cleanNumber(number: string): string {
  return typeof number === 'string' ? number.replace(/\D/g, '') : ''
}

/**
 * Validates and processes a value based on the specified field.
 *
 * @param {string} field - The field to validate.
 * @param {string} value - The value to be validated.
 * @returns {string|undefined} - The processed value or `undefined` if validation fails.
 */
export const validators = (field: string, value: string) => {
  let finalValue = value

  switch (field) {
    case 'phone':
      finalValue = value.length > 8 ? undefined : cleanNumber(value)
      break
    case 'name':
      finalValue = cleanName(value.trimStart())
      break
    case 'totalGiftcards':
      finalValue = value.length > 10 ? undefined : cleanNumber(value)
      break
    case 'document':
      finalValue = value.length > 12 ? undefined : formatRut(value)
      break
    case 'giftCardNumber':
      finalValue = value.length > 18 ? undefined : cleanNumber(value)
      break
    case 'code':
      finalValue = value.length > 6 ? undefined : cleanNumber(value)
      break
    case 'valueGiftcard':
      finalValue = cleanNumber(value)
      break
  }

  return finalValue
}
