import { useMemo } from 'react'
import { useRouter } from 'next/router'

import { IMAGES } from 'shared/utils/contants'
import {
  Button,
  Column,
  Container,
  Picture,
  Row,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { useContentful, useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { Context as ResponsiveContext } from '@smu-chile/pkg-unimarc-components/reactResponsive'
import styles from './success.module.css'

export const Success = () => {
  const { push } = useRouter()
  const { innerWidth } = useMobile()
  const isMobile = innerWidth < 1280
  const data = JSON.parse(localStorage.getItem('paymentForm') ?? '{}')

  const bannerGiftcardAsset = useContentful({
    id_contentful: 'banner-giftcard-image',
    options: {
      'sys.id': IMAGES.BANNER_GIFTCARD
    },
    type: 'assets'
  })
  const bannerGiftcardImage =
    bannerGiftcardAsset?.data?.['items']?.[0]?.fields?.file?.url

  const withProvider: number = useMemo(() => {
    const hasInnerWith: number = isMobile && !innerWidth ? 500 : 1400
    return innerWidth ? innerWidth : hasInnerWith
  }, [innerWidth, isMobile])

  return (
    <ResponsiveContext.Provider value={{ width: withProvider }}>
      <BigScreen>
        <Container
          alignItems='center'
          backgroundColor={getGlobalStyle('--color-base-white')}
          isWrap
          padding='0 30rem'
        >
          <Column alignItems='center'>
            <Column>
              <Row justifyContent='center'>
                <Text
                  fontSize='2xl'
                  fontWeight='medium'
                  textAlign='center'
                >
                  Gracias {data?.name},
                </Text>
              </Row>
              <Row
                justifyContent='center'
                margin='0 0 3px'
              >
                <Text
                  fontSize='2xl'
                  fontWeight='medium'
                  textAlign='center'
                >
                  tu compra fue realizada con éxito
                </Text>
              </Row>
            </Column>
            <Picture
              alt='cake'
              height='311px'
              src={bannerGiftcardImage}
              width='362px'
            />
            <Row
              justifyContent='center'
              margin='3px 0 23px'
            >
              <Text
                fontSize='xl'
                textAlign='center'
              >
                Puedes ver más información en tu correo.
              </Text>
            </Row>
            <Row justifyContent='center'>
              <Button
                background={getGlobalStyle('--color-background-dark-orange')}
                borderRadius='24px'
                customClassName={styles.button__color}
                fontWeight='500'
                label='Regalar otra Giftcard'
                maxWidth='343px'
                onClick={() => {
                  return push('/giftcard')
                }}
                width='100%'
              />
            </Row>
          </Column>
        </Container>
      </BigScreen>
      <SmallScreen>
        <Container
          alignItems='center'
          backgroundColor={getGlobalStyle('--color-base-white')}
          isWrap
          margin='20px 0 0 0'
          padding='0 1rem'
        >
          <Column
            alignItems='center'
            gap='25px'
          >
            <Column>
              <Row justifyContent='center'>
                <Text
                  fontSize='2xl'
                  fontWeight='medium'
                  textAlign='center'
                >
                  Gracias {data?.name},
                </Text>
              </Row>
              <Row justifyContent='center'>
                <Text
                  fontSize='2xl'
                  fontWeight='medium'
                  textAlign='center'
                >
                  tu compra fue realizada con éxito
                </Text>
              </Row>
            </Column>
            <Row justifyContent='center'>
              <Picture
                alt='cake'
                height='211px'
                src={bannerGiftcardImage}
                width='262px'
              />
            </Row>
            <Row
              justifyContent='center'
              padding='0 5rem'
            >
              <Text
                fontSize='lg'
                textAlign='center'
              >
                Puedes ver más información en tu correo.
              </Text>
            </Row>
            <Row justifyContent='center'>
              <Button
                background={getGlobalStyle('--color-background-dark-orange')}
                borderRadius='24px'
                label='Regalar otra Giftcard'
                maxWidth='311px'
                onClick={() => {
                  return push('/giftcard')
                }}
                width='100%'
              />
            </Row>
          </Column>
        </Container>
      </SmallScreen>
    </ResponsiveContext.Provider>
  )
}
