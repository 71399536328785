import { useState } from 'react'

export const nameValidator = (name: string) => {
  if (!name) {
    return 'Número de Giftcard es requerido'
  }

  if (name.length < 3) {
    return 'Por favor ingrese mínimo 3 caracteres'
  }

  if (!new RegExp(/^\d+$/).test(name)) {
    return 'Por favor escribe solo números'
  }

  return ''
}

export const phoneValidator = (phone) => {
  if (!phone) {
    return 'Pin es requerido'
  }

  if (!new RegExp(/^\d+$/).test(phone)) {
    return 'Por favor escribe solo números'
  }

  if (!new RegExp(/^[0-9]{6}$/).test(phone)) {
    return 'Por favor ingresa máximo 6 números'
  }

  return ''
}

export const useFormValidator = () => {
  const [errors, setErrors] = useState({
    giftCardNumber: {
      dirty: true,
      touched: false,
      error: false,
      message: ''
    },
    code: {
      dirty: true,
      touched: false,
      error: false,
      message: ''
    }
  })

  const validateForm = ({ form, field, errors, onChange }) => {
    let isValid = true

    const nextErrors = JSON.parse(JSON.stringify(errors))

    const { giftCardNumber, code } = form || {}

    if (
      nextErrors?.giftCardNumber?.dirty &&
      (field ? field === 'giftCardNumber' : true)
    ) {
      nextErrors.giftCardNumber.touched = true
      const emailMessage = nameValidator(giftCardNumber)
      nextErrors.giftCardNumber.error = !!emailMessage
      nextErrors.giftCardNumber.message = emailMessage
      if (emailMessage) isValid = false
    }

    if (nextErrors?.code?.dirty && (field ? field === 'code' : true)) {
      nextErrors.code.touched = true
      const phoneMessage = phoneValidator(code)
      nextErrors.code.error = !!phoneMessage
      nextErrors.code.message = phoneMessage
      if (phoneMessage) isValid = false
    }

    setErrors(nextErrors)

    onChange({ form, errors: nextErrors })

    return {
      isValid,
      errors: nextErrors
    }
  }

  return {
    validateForm,
    errors
  }
}
