import { Dispatch, SetStateAction, useState } from 'react'
import {
  Column,
  DesktopModalWrapper,
  MobileModalWrapper
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { QuotationForm } from '../QuotationForm'
import { SuccessContent } from '../SuccessContent'
import { useRouter } from 'next/router'
import styles from '../home.module.css'
import classNames from 'classnames'

export const QuotationContent = ({
  isMobile,
  showModal,
  setShowModal
}: {
  isMobile: boolean
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
}) => {
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [executeFunction, setExecuteFunction] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { push } = useRouter()

  const onClose = () => {
    setShowModal(false)
    setShowSuccessModal(false)
  }

  const onApply = () => {
    if (isValid) setExecuteFunction(true)
  }

  const onCloseAndRedirect = () => {
    setShowSuccessModal(false)
    push('/')
  }

  return (
    <>
      {!isMobile && (
        <>
          <DesktopModalWrapper
            blockId='quotation'
            footerChildrenProps={{
              default: true,
              buttonDefaultProps: {
                background: isValid
                  ? getGlobalStyle('--color-background-citrus')
                  : getGlobalStyle('--color-primary-disabled'),
                borderRadius: getGlobalStyle('--border-radius-xl'),
                customClassName: classNames({
                  [styles.buttonBackground]: isValid,
                  [styles.buttonCursor]: !isValid
                }),
                height: '45px',
                isLoading,
                label: 'Cotiza',
                onClick: onApply,
                width: '80%'
              }
            }}
            headerCloseIcon={{ sizes: '2xs', default: true }}
            headerTitle={'Contacto Empresas'}
            modalConfigsProps={{
              customContainerClassName: styles['bussiness-modal'],
              isOpen: showModal,
              toggleOutside: onClose,
              maxWidth: '440px',
              maxHeight: '518px'
            }}
          >
            <QuotationForm
              executeFunction={executeFunction}
              setExecuteFunction={setExecuteFunction}
              setIsLoading={setIsLoading}
              setIsValid={setIsValid}
              setShowModal={setShowModal}
              setShowSuccessModal={setShowSuccessModal}
            />
          </DesktopModalWrapper>
          <DesktopModalWrapper
            blockId='success'
            borderEdge='none'
            footerChildrenProps={{
              default: true,
              buttonDefaultProps: {
                background: getGlobalStyle('--color-base-white'),
                border: `1px solid ${getGlobalStyle(
                  '--color-background-dark-orange'
                )}`,
                borderRadius: getGlobalStyle('--border-radius-xl'),
                color: getGlobalStyle('--color-background-dark-orange'),
                height: '45px',
                label: 'Volver al inicio',
                onClick: onClose,
                width: '100%'
              }
            }}
            headerCloseIcon={{ sizes: '2xs' }}
            headerTitle={' '}
            modalConfigsProps={{
              isOpen: showSuccessModal,
              toggleOutside: onClose,
              maxWidth: '440px',
              maxHeight: '518px'
            }}
          >
            <SuccessContent />
          </DesktopModalWrapper>
        </>
      )}
      {isMobile && showModal && (
        <MobileModalWrapper
          blockId='quotation'
          body={
            <Column padding='0 0 4rem 0'>
              <QuotationForm
                executeFunction={executeFunction}
                setExecuteFunction={setExecuteFunction}
                setIsLoading={setIsLoading}
                setIsValid={setIsValid}
                setShowModal={setShowModal}
                setShowSuccessModal={setShowSuccessModal}
              />
            </Column>
          }
          buttonBackground={
            isValid && getGlobalStyle('--color-background-citrus')
          }
          buttonIsLoading={isLoading}
          buttonLabel='Cotiza'
          dragProps={{ draggButton: false }}
          headerPadding='30px 0'
          headerTitle={'Contacto Empresas'}
          iconSize={20}
          isEnableButton={isValid}
          modalConfigsProps={{
            openAnimation: true,
            fullScreenSticky: true,
            hiddenX: true,
            isAutoHeight: true,
            isOpen: showModal,
            isWindowBlocked: true,
            marginFullScreen: '1vh 0 0',
            stickyBottom: true
          }}
          onApply={onApply}
          onClose={onClose}
        />
      )}
      {isMobile && showSuccessModal && (
        <MobileModalWrapper
          blockId='success'
          body={
            <Column padding='5rem 0 0 0'>
              <SuccessContent />
            </Column>
          }
          buttonBackground={getGlobalStyle('--color-base-white')}
          buttonBorder={`1px solid ${getGlobalStyle(
            '--color-background-dark-orange'
          )}`}
          buttonColor={getGlobalStyle('--color-background-dark-orange')}
          buttonIsLoading={isLoading}
          buttonLabel='Volver al inicio'
          dragProps={{
            draggButton: false
          }}
          headerPadding='30px 0'
          headerTitle={' '}
          hideHeaderDivider
          iconSize={20}
          isEnableButton
          modalConfigsProps={{
            openAnimation: true,
            fullScreenSticky: true,
            hiddenX: true,
            isAutoHeight: true,
            isOpen: showSuccessModal,
            isWindowBlocked: true,
            marginFullScreen: '1vh 0 0',
            stickyBottom: true
          }}
          onApply={onCloseAndRedirect}
          onClose={onClose}
        />
      )}
    </>
  )
}
