import { Context } from '@smu-chile/pkg-unimarc-components/reactResponsive'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import React from 'react'

interface ResponsiveContextProps {
  children: React.ReactElement
  isMobile: boolean
}

export const ResponsiveContext = ({
  isMobile,
  children
}: ResponsiveContextProps): React.ReactElement => {
  const { innerWidth } = useMobile()
  const width = isMobile ? innerWidth : 1280

  return <Context.Provider value={{ width }}>{children}</Context.Provider>
}
