import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'

// Packages
import {
  ContentfulResponse,
  generateBreadcrumbJoin,
  IContentfulResponse,
  useContentful,
  useOnScreen
} from '@smu-chile/pkg-unimarc-hooks'
import { Container, Column } from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'

// Components
import { BackboneHome } from './Backbone'
import { BackboneShowCaseWeb } from './Backbone/BackboneShowCaseWeb'
import { IShowCaseWebProps } from './ShowCaseWeb'
import { PagesContainerDescription } from '../PagesContainerDescription'
import { PropsBannersWeb } from './BannersWeb'
import { QuotationContent } from './QuotationContent'
import { ShowCase } from 'components/Home/ShowCase'

// Shared
import { PropsHome } from 'shared/interfaces/Home'

import styles from './home.module.css'

const ShowCaseWeb = dynamic<IShowCaseWebProps>(
  () => {
    return import('./ShowCaseWeb').then((mod) => {
      return mod.ShowCaseWeb
    })
  },
  {
    loading: () => {
      return <BackboneShowCaseWeb />
    }
  }
)

const BannersWeb = dynamic<PropsBannersWeb>(() => {
  return import('./BannersWeb').then((mod) => {
    return mod.BannersWeb
  })
})

const CURRENT_IDENTIFIER = '[GC] huincha 1'

export const Home = ({
  contentTypeId = 'home',
  data: serverData,
  isMobile,
  salesChannel
}: PropsHome) => {
  const [showModal, setShowModal] = useState(false)
  const router = useRouter()

  const { elementRef: refCase, isIntersecting: isIntersectingCase } =
    useOnScreen({ rootMargin: '0px' })
  const isFallback = router.isFallback
  const componentsToFirstLoad = isMobile ? 3 : 4

  let undefinedContent = 0

  const customTitles = {
    h2: {
      customFontSize: isMobile
        ? getGlobalStyle('--font-size-titles-xs')
        : getGlobalStyle('--font-size-titles-lg')
    },
    h3: {
      customFontSize: isMobile
        ? getGlobalStyle('--font-size-titles-2xs')
        : getGlobalStyle('--font-size-titles-md')
    }
  }

  const clientData = useContentful({
    options: { content_type: contentTypeId },
    reactQuery: { enabled: !serverData }
  })
  const populatedData = clientData.data
    ? new ContentfulResponse(clientData.data as IContentfulResponse)
        .populateEntries()
        .getResponse()
    : []

  const data = serverData || populatedData

  const handleRenderValidate = ({ isRender = false, index = 0 }) => {
    return (
      isRender || index < componentsToFirstLoad || typeof window === 'undefined'
    )
  }

  useEffect(() => {
    generateBreadcrumbJoin([])
  }, [])

  if (!data || isFallback) {
    return <BackboneHome />
  }

  return (
    <Container
      backgroundColor='#FFF'
      className={styles['container']}
      margin={isMobile ? 'none' : '16px 0 0 0'}
      tagName='main'
    >
      <QuotationContent
        isMobile={isMobile}
        setShowModal={setShowModal}
        showModal={showModal}
      />
      <Column
        alignItems='center'
        gap={isMobile ? '12px' : '60px'}
      >
        {data &&
          Object.entries(data)?.length > 0 &&
          Object.entries(data).map((content, index) => {
            if (content[0].split('_')[0] === 'showcase') {
              return (
                <Column
                  alignItems='center'
                  key={index}
                  ref={refCase}
                >
                  <ShowCase
                    data={content[1]['slider']}
                    isMobile={isMobile}
                  />
                </Column>
              )
            }

            if (
              /showcaseWeb/i.test(content[0]) &&
              content[1]?.['slider']?.length > 0
            ) {
              return (
                <Column
                  alignItems='center'
                  key={index}
                  padding='0 0 60px 0'
                  ref={refCase}
                >
                  {handleRenderValidate({
                    isRender: isIntersectingCase,
                    index
                  }) && (
                    <ShowCaseWeb
                      cfIndex={index + 1 - undefinedContent}
                      data={content[1]['slider']}
                      isMobile={isMobile}
                      salesChannel={salesChannel}
                    />
                  )}
                </Column>
              )
            }

            if (
              /featureContentBannersWeb/i.test(content[0]) ||
              /bannerAndText/i.test(content[0])
            ) {
              return (
                <BannersWeb
                  cfIndex={index + 1 - undefinedContent}
                  data={content[1]}
                  isMobile={isMobile}
                  isPriority={index === 0}
                  key={index}
                  onClick={() => {
                    if (content[1].label == CURRENT_IDENTIFIER)
                      setShowModal(true)
                  }}
                  saleChannel={salesChannel}
                  titleSizes={customTitles}
                />
              )
            }

            if (
              /term/i.test(content[0]) &&
              content[1]?.subTitle &&
              content[1]?.idFormato == 1
            ) {
              const termData = Object.entries(data)
                .filter((d) => {
                  return /term/i.test(d[0])
                })
                .map((td) => {
                  return td[1]
                })

              return (
                <Column
                  alignItems='center'
                  key={index}
                  padding='0 0 60px 0'
                  ref={refCase}
                >
                  <PagesContainerDescription
                    isMobile={isMobile}
                    items={termData}
                  />
                </Column>
              )
            }
            undefinedContent += 1
            return null
          })}
      </Column>
    </Container>
  )
}
