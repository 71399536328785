export const formatDate = (date: string) => {
  const formatDate = new Date(date)

  const finalDate = formatDate.toLocaleString('es-ES', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  })

  const finalDateSplit = finalDate.split(',')

  return `${finalDateSplit[0]} - ${finalDateSplit[1]?.trim()}`
}
