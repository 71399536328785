import { useEffect, useState } from 'react'
import {
  Column,
  Row,
  Text,
  Icon,
  RadioSelector
} from '@smu-chile/pkg-unimarc-components'
import styles from '../cart.module.css'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { FormInput } from 'components/FormInput'
import { IFormValues } from 'shared/utils/hooks'
import {
  checkPeymentFormValidation,
  initialPaymentState,
  setValitadionForm,
  validationPaymentState
} from 'shared/helpers'
import { IPaymentValidation } from 'shared/interfaces/IFormValidations'

type FormCartProps = {
  handleIsValid?: (value?: boolean) => void
  isMobile?: boolean
  onChange?: (value?) => void
  width?: string
}

export const FormCart = ({
  handleIsValid,
  onChange,
  width = '740px'
}: FormCartProps) => {
  const [formData, setFormData] = useState<IFormValues>(initialPaymentState)
  const [validationData, setValidationData] = useState<IPaymentValidation>(
    validationPaymentState
  )

  const handleChangeUserInfo = (key?, value?) => {
    setFormData({ ...formData, [key]: value })
  }

  const handleFormValidation = (value?) => {
    const key = Object.keys(value)[0]
    key !== 'undefined' &&
      setValidationData(setValitadionForm(validationData, value))
  }

  useEffect(() => {
    handleIsValid(checkPeymentFormValidation(validationData))
  }, [validationData])

  useEffect(() => {
    onChange(formData)
  }, [formData])

  return (
    <Column
      border={`solid 0.3px ${getGlobalStyle('--color-neutral-gray-divider')}`}
      borderRadius='12px'
      gap='30px'
      padding='16px'
      width={width}
    >
      <Row>
        <Text
          fontSize='xl'
          fontWeight='semibold'
        >
          Tus datos
        </Text>
      </Row>
      <Row>
        <Column gap='30px'>
          <FormInput
            keyName='name'
            label='Nombre'
            onValidText={handleChangeUserInfo}
            onValidation={handleFormValidation}
            type='text'
          />
          <FormInput
            keyName='rut'
            label='RUT'
            onValidText={handleChangeUserInfo}
            onValidation={handleFormValidation}
            type='text'
          />
          <FormInput
            keyName='email'
            label='Correo'
            onValidText={handleChangeUserInfo}
            onValidation={handleFormValidation}
            type='email'
          />
          <FormInput
            keyName='phone'
            label='Teléfono'
            onValidText={handleChangeUserInfo}
            onValidation={handleFormValidation}
            type='email'
          />
        </Column>
      </Row>
      <Row>
        <Text
          fontSize='xl'
          fontWeight='semibold'
        >
          Medios de pago
        </Text>
      </Row>
      <Row customClassName={styles.logo__container}>
        <RadioSelector
          checked
          customClassName={styles.radio__color}
          site='unimarc'
        />
        <Icon
          color='white'
          name='WebPayPlus'
        />
      </Row>
    </Column>
  )
}
